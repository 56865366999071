export enum HitterSide {
  LHH = 'LHH',
  RHH = 'RHH',
}

export enum HitterSafety {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}

export enum HitOutcomeCode {
  Single = '1B',
  Double = '2B',
  Triple = '3B',
  HomeRun = 'HR',
  FieldOut = 'FO',
  Unknown = '',
}
