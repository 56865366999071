import { ArrowLeftIcon } from '@radix-ui/react-icons';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Strong,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { PlateView } from 'components/common/plate-view';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import { ResetTrainingDialog } from 'components/sections/pitch-list/dialogs';
import env from 'config';
import { IAimingContext } from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import {
  IQuickSessionContext,
  QuickSessionStep,
} from 'contexts/quick-session.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { expandPitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PLATE, IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'QuickSessionSelectPitch';

const AUTO_SEND_TO_MACHINE = true;

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  hittersCx: IHittersContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  quickCx: IQuickSessionContext;
  videosCx: IVideosContext;
  aimingCx: IAimingContext;
}

interface IDialogs {
  dialogCopy?: number;
  dialogReset?: number;
  dialogTraining?: number;
}

interface IState extends IDialogs {
  tags: string;

  /** e.g. for training without unmounting as soon as the final pitch is trained */
  managePitches?: IPitch[];
}

export class SelectPitch extends React.Component<IProps, IState> {
  private init = false;
  private resendTimeout?: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);

    this.state = {
      tags: '',
    };

    this.selectPitch = this.selectPitch.bind(this);
    this.sendPitch = this.sendPitch.bind(this);

    this.renderBodySidebar = this.renderBodySidebar.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderHeaderSidebar = this.renderHeaderSidebar.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    this.props.matchingCx.updatePitches({
      pitches: this.props.quickCx.activePitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.globalCx.dialogs.length === 0) {
      const ballBecameReady =
        prevProps.machineCx.lastBallCount !==
          this.props.machineCx.lastBallCount &&
        this.props.machineCx.lastBallCount === 1;

      if (AUTO_SEND_TO_MACHINE && ballBecameReady) {
        this.sendPitch(true);
      }
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.resendTimeout);
  }

  // will dequeue itself if called in rapid succession
  private sendPitch(auto: boolean) {
    if (this.props.globalCx.dialogs.length > 0) {
      console.warn(
        `${COMPONENT_NAME}: skipping sendPitch while a dialog was open`
      );
      clearTimeout(this.resendTimeout);
      return;
    }

    if (auto && !this.props.machineCx.checkActive(true)) {
      // silently skip auto-send if not connected
      return;
    }

    clearTimeout(this.resendTimeout);

    this.resendTimeout = setTimeout(() => {
      this.props.aimingCx.sendToMachine({
        training: false,
        skipPreview: false,
        trigger: `${COMPONENT_NAME} > sendPitch`,
        list: this.props.quickCx.active,
        onSuccess: (success) => {
          if (!success) {
            NotifyHelper.warning({
              message_md: 'Failed to send instructions to the machine.',
            });
          }
        },
      });
    }, 500);
  }

  private async selectPitch(pitch: IPitch) {
    if (this.props.matchingCx.isPitchTrained(pitch)) {
      this.props.aimingCx.setPlate(DEFAULT_PLATE);

      await this.props.aimingCx.setPitch(pitch);

      if (AUTO_SEND_TO_MACHINE) {
        this.sendPitch(true);
      }
      return;
    }

    // can't train yet
    if (!this.props.matchingCx.readyToTrain()) {
      return;
    }

    // attempt to train pitch if possible
    this.setState({
      dialogTraining: Date.now(),
      managePitches: [pitch],
    });
  }

  private renderTrainingDialog() {
    if (!this.state.dialogTraining) {
      return;
    }

    if (!this.props.matchingCx.aggReady) {
      return;
    }

    if (!this.state.managePitches) {
      return;
    }

    if (this.state.managePitches.length === 0) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
          <TrainingContext.Consumer>
            {(trainingCx) => (
              <TrainingDialog
                key={this.state.dialogTraining}
                identifier="QS-TrainingDialog"
                machineCx={this.props.machineCx}
                trainingCx={trainingCx}
                pitches={this.state.managePitches ?? []}
                threshold={this.props.machineCx.machine.training_threshold}
                onClose={() =>
                  this.setState({ dialogTraining: undefined }, () => {
                    if (!this.state.managePitches) {
                      return;
                    }

                    this.props.matchingCx.updatePitches({
                      pitches: this.state.managePitches,
                      includeHitterPresent: false,
                      includeLowConfidence: true,
                    });
                  })
                }
              />
            )}
          </TrainingContext.Consumer>
        </TrainingProvider>
      );
    }

    return (
      <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
        <TrainingContext.Consumer>
          {(trainingCx) => (
            <PresetTrainingDialog
              key={this.state.dialogTraining}
              identifier="QS-PT-TrainingDialog"
              machineCx={this.props.machineCx}
              trainingCx={trainingCx}
              pitches={this.state.managePitches ?? []}
              onClose={() =>
                this.setState({ dialogTraining: undefined }, () => {
                  if (!this.state.managePitches) {
                    return;
                  }

                  this.props.matchingCx.updatePitches({
                    pitches: this.state.managePitches,
                    includeHitterPresent: false,
                    includeLowConfidence: true,
                  });
                })
              }
            />
          )}
        </TrainingContext.Consumer>
      </TrainingProvider>
    );
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogCopy && (
          <AuthContext.Consumer>
            {(authCx) => (
              <PitchListsContext.Consumer>
                {(listsCx) =>
                  this.props.aimingCx.pitch && (
                    <CopyPitchesDialog
                      key={this.state.dialogCopy}
                      identifier="QS-CopyPitchDialog"
                      authCx={authCx}
                      listsCx={listsCx}
                      title="Copy Pitch"
                      description="Please select a pitch list to copy this pitch into."
                      excludedListIDs={
                        this.props.quickCx.active
                          ? [this.props.quickCx.active._id]
                          : undefined
                      }
                      pitches={[this.props.aimingCx.pitch]}
                      onCreated={() => this.setState({ dialogCopy: undefined })}
                      onClose={() => this.setState({ dialogCopy: undefined })}
                    />
                  )
                }
              </PitchListsContext.Consumer>
            )}
          </AuthContext.Consumer>
        )}

        {this.state.dialogReset &&
          this.props.matchingCx.aggReady &&
          this.state.managePitches && (
            <ResetTrainingDialog
              key={this.state.dialogReset}
              pitches={this.state.managePitches}
              onClose={() =>
                this.setState({ dialogReset: undefined }, () => {
                  if (!this.state.managePitches) {
                    return;
                  }

                  this.props.matchingCx.updatePitches({
                    pitches: this.state.managePitches,
                    includeHitterPresent: false,
                    includeLowConfidence: true,
                  });
                })
              }
            />
          )}
      </>
    );
  }

  private renderBodySidebar() {
    const safeTags = `quick-session,${this.state.tags}`;
    const noBall = this.props.machineCx.lastBallCount === 0;

    const machineReady =
      this.props.machineCx.checkActive(true) && this.props.machineCx.calibrated;

    const requireSend =
      !AUTO_SEND_TO_MACHINE && this.props.aimingCx.checkRequireSend();

    const showSendButton =
      machineReady && this.props.aimingCx.pitch && requireSend;
    const showFireButton =
      machineReady && this.props.aimingCx.pitch && !requireSend;

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <AuthContext.Consumer>
          {(authCx) => (
            <PlateView
              cookiesCx={this.props.cookiesCx}
              authCx={authCx}
              machineCx={this.props.machineCx}
              matchingCx={this.props.matchingCx}
              pitch={noBall ? undefined : this.props.aimingCx.pitch}
              hitter={this.props.hittersCx.active}
              disabled={
                noBall ||
                !!this.state.dialogTraining ||
                !this.props.aimingCx.pitch ||
                !this.props.matchingCx.isPitchTrained(this.props.aimingCx.pitch)
              }
              onUpdate={(location) => {
                this.props.aimingCx.setPlate(location);

                if (AUTO_SEND_TO_MACHINE) {
                  this.sendPitch(true);
                }
              }}
              drawShots
              border
            />
          )}
        </AuthContext.Consumer>

        {!machineReady && <MachineCalibrateButton className="width-100p" />}

        {machineReady && (
          <>
            <div hidden={!showSendButton}>
              <Button
                className="width-100p"
                color={RADIX.COLOR.SEND_PITCH}
                disabled={
                  this.props.matchingCx.loading ||
                  !this.props.aimingCx.pitch ||
                  !this.props.machineCx.checkActive(true)
                }
                onClick={() => this.sendPitch(false)}
              >
                {t('common.load-pitch')}
              </Button>
            </div>

            <div hidden={!showFireButton}>
              <MachineFireButton
                className="width-100p"
                cookiesCx={this.props.cookiesCx}
                machineCx={this.props.machineCx}
                hitter={this.props.hittersCx.active}
                aimingCx={this.props.aimingCx}
                tags={safeTags}
                firing
              />
            </div>
          </>
        )}
      </Flex>
    );
  }

  private renderHeaderSidebar() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {this.props.hittersCx.getInput('level')}
        {this.props.hittersCx.getInput('hitter')}

        {env.enable.fire_tags && (
          <CommonTextInput
            id="quick-session-tags"
            value={this.state.tags}
            className="text-uppercase"
            placeholder={t('common.eg-tags').toString()}
            onChange={(v) => this.setState({ tags: v?.toUpperCase() ?? '' })}
          />
        )}
      </Flex>
    );
  }

  private renderHeader() {
    const isActive = this.props.machineCx.checkActive(true);
    const untrained = this.props.quickCx.activePitches.filter(
      (p) => !this.props.matchingCx.isPitchTrained(p)
    );
    const details = this.props.quickCx.active?.card;

    return (
      <Flex gap={RADIX.FLEX.GAP.MD} justify="between">
        <Avatar
          size="6"
          src={this.props.quickCx.avatarURLs[details?.avatar_path ?? '']?.url}
          fallback={
            this.props.quickCx.active
              ? PitchListHelper.getAvatarFallback(this.props.quickCx.active)
              : '??'
          }
        />

        <Box flexGrow="1">
          <Heading size={RADIX.HEADING.SIZE.LG}>
            {details?.name ?? t('hitters.anonymous')}
          </Heading>

          <Flex gap={RADIX.FLEX.GAP.SM}>
            {details?.release && (
              <Badge color={RADIX.COLOR.NEUTRAL}>
                {PitchListHelper.shortPitcherRelease(details.release)}
              </Badge>
            )}

            {details?.level && (
              <Badge color={RADIX.COLOR.NEUTRAL}>{details.level}</Badge>
            )}

            {details?.hand && (
              <Badge color={RADIX.COLOR.NEUTRAL}>{details.hand}</Badge>
            )}
          </Flex>
        </Box>

        {isActive && untrained.length > 0 && (
          <Box>
            <Button
              size={RADIX.BUTTON.SIZE.MD}
              disabled={!isActive}
              color={RADIX.COLOR.WARNING}
              className="width-200px"
              onClick={() => {
                if (!this.props.machineCx.checkActive()) {
                  return;
                }

                this.setState({
                  dialogTraining: Date.now(),
                  managePitches: untrained,
                });
              }}
            >
              Train All
            </Button>
          </Box>
        )}
      </Flex>
    );
  }

  private renderBody() {
    const isActive = this.props.machineCx.checkActive(true);

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {this.props.quickCx.activePitches.map((pitch) => {
          const selected = pitch._id === this.props.aimingCx.pitch?._id;
          const speedMPH = TrajHelper.getSpeedMPH(pitch.traj);
          const spin = BallHelper.getSafeNetSpin(pitch.bs);
          const breaks = TrajHelper.getBreaks(pitch.traj);
          const trained = this.props.matchingCx.isPitchTrained(pitch);

          return (
            <Flex
              key={`pitch-${pitch._id}`}
              gap={RADIX.FLEX.GAP.MD}
              className={`Pitch rounded-md cursor-pointer ${
                selected ? 'extra-foreground' : 'foreground'
              }`}
              title={selected ? 'Selected' : undefined}
              data-selected={selected}
              data-trained={trained}
              onClick={() => {
                if (!isActive) {
                  return;
                }

                if (!this.props.matchingCx.aggReady) {
                  NotifyHelper.info({
                    message_md: `Please wait while training data loads...`,
                  });
                  return;
                }

                this.selectPitch(pitch);
              }}
            >
              <Box>
                <Avatar
                  color={selected ? RADIX.COLOR.ACCENT : RADIX.COLOR.NEUTRAL}
                  fallback={pitch.type ?? '??'}
                />
              </Box>

              <Box flexGrow="1">
                <Strong>{expandPitchType(pitch.type)}</Strong>

                <Flex justify="between" gap={RADIX.FLEX.GAP.LG}>
                  <Text color="gray">
                    {speedMPH?.toFixed(1)} <small>MPH</small>
                  </Text>
                  <Text color="gray">
                    {spin?.toFixed(0)} <small>RPM</small>
                  </Text>
                  <Text color="gray">
                    {breaks.xInches.toFixed(1)} <small>IN (H)</small>
                  </Text>
                  <Text color="gray">
                    {breaks.zInches.toFixed(1)} <small>IN (V)</small>
                  </Text>
                </Flex>
              </Box>

              <Box className="valign-center">
                <Spinner loading={!this.props.matchingCx.aggReady}>
                  <Badge
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      width: '65px',
                    }}
                    title={
                      trained ? 'Right-click to reset training data' : undefined
                    }
                    color={trained ? RADIX.COLOR.SUCCESS : RADIX.COLOR.WARNING}
                    onContextMenu={(e) => {
                      if (trained) {
                        e.preventDefault();

                        this.setState({
                          dialogReset: Date.now(),
                          managePitches: [pitch],
                        });
                      }
                    }}
                  >
                    {trained ? 'Trained' : 'Untrained'}
                  </Badge>
                </Spinner>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <Button
              size={RADIX.BUTTON.SIZE.SM}
              className="btn-floating"
              color={RADIX.COLOR.NEUTRAL}
              onClick={() =>
                this.props.quickCx.setStep(QuickSessionStep.SelectCard)
              }
            >
              <ArrowLeftIcon /> {t('common.back')}
            </Button>
          </Box>

          <Flex direction="column" gap={RADIX.FLEX.GAP.SIDEBAR}>
            <CommonContentWithSidebar
              left={this.renderHeader()}
              right={this.renderHeaderSidebar()}
            />

            <CommonContentWithSidebar
              left={this.renderBody()}
              right={this.renderBodySidebar()}
            />
          </Flex>
        </Flex>

        {this.renderDialogs()}
        {this.renderTrainingDialog()}
      </ErrorBoundary>
    );
  }
}
