import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ISectionsContext } from 'contexts/sections.context';
import { lightFormat, parseISO } from 'date-fns';
import { SectionName } from 'enums/route.enums';
import { CheckMode } from 'enums/tables';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitchListExtType, TrainingStatus } from 'lib_ts/enums/pitches.enums';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { ICopyPitchLists } from 'lib_ts/interfaces/pitches/i-copy-pitch-list';
import {
  IPitchList,
  IPitchListPutManyRequest,
  safeFolder,
} from 'lib_ts/interfaces/pitches/i-pitch-list';
import { ISearchPitches } from 'lib_ts/interfaces/pitches/i-search-pitches';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';
import { StateTransformService } from 'services/state-transform.service';

const RECENT_LENGTH = 5;
export const MAX_SEARCH_LIMIT = 1_000;

// instead of aggressively loading and using the shots dictionary
const USE_AGG_SHOT_DICT = true;

const READ_ONLY_LIST_TYPES: PitchListExtType[] = [
  PitchListExtType.Card,
  PitchListExtType.Reference,
  PitchListExtType.Sample,
];

export const SEARCH_ID = '--SEARCH--';
interface IOptionsDict {
  name: string[];
  status: TrainingStatus[];
  _created: string[];
}

const sanitizeFolders = (lists: IPitchList[]) => {
  /** ensure that any repeated instances of FOLDER_SEPARATOR are collapsed into no more than 1 */
  return lists.map((list) => {
    const out = Object.assign({}, list);
    out.folder = safeFolder(list.folder);
    return out;
  });
};

export interface IPitchListsContext {
  /** insert list id at index 0 whenever user opens it */
  recentIDs: string[];

  lists: IPitchList[];

  options: IOptionsDict;

  active?: IPitchList;

  activePitches: IPitch[];

  loading: boolean;

  search: ISearchPitches;
  readonly setSearch: (payload: ISearchPitches | undefined) => Promise<void>;

  readonly createList: (
    payload: Partial<IPitchList>
  ) => Promise<IPitchList | undefined>;

  readonly copyList: (
    payload: Partial<IPitchList>
  ) => Promise<IPitchList | undefined>;

  readonly updateList: (config: {
    payload: Partial<IPitchList>;
    successMsg?: string;
    silently?: boolean;
  }) => Promise<IPitchList | undefined>;

  readonly copyLists: (
    payload: ICopyPitchLists,
    successMsg?: string
  ) => Promise<boolean>;
  readonly updateLists: (
    payload: IPitchListPutManyRequest,
    successMsg?: string
  ) => Promise<boolean>;

  readonly deleteLists: (ids: string[]) => Promise<boolean>;

  readonly renameFolder: (
    payload: { _parent_id: string; fromFolder: string; toFolder: string },
    successMsg?: string
  ) => Promise<boolean>;

  readonly updatePitches: (config: {
    payloads: Partial<IPitch>[];
    silently?: boolean;
    successMsg?: string;
  }) => Promise<IPitch[] | undefined>;

  readonly deletePitches: (ids: string[]) => Promise<boolean>;

  /** refreshes activePitches based on value of active (if exists) */
  readonly refreshActive: () => void;

  /** refreshes lists from server (e.g. if someone else changes a list's visiblity) */
  readonly refreshLists: (notify?: boolean) => void;

  readonly uploadCSV: (files: File[]) => Promise<boolean>;
  readonly uploadAvatar: (
    list_id: string,
    files: File[],
    onProgress?: (ev: ProgressEvent) => void
  ) => Promise<IPitchList | undefined>;

  readonly activeReadOnly: () => boolean;

  readonly updateActiveTrainingStatus: () => void;
  readonly rebuildFromActive: (ids: string[]) => Promise<void>;

  readonly onCheckOneList: () => void;
  readonly onCheckAllLists: (mode: CheckMode) => void;
}

const DEFAULT_SEARCH: ISearchPitches = {
  sortDir: 'desc',
  sortKey: '_created',
  limit: 50,
};

const DEFAULT: IPitchListsContext = {
  recentIDs: [],
  options: {
    name: [],
    status: [],
    _created: [],
  },
  lists: [],
  activePitches: [],

  onCheckOneList: () => console.debug('not init'),
  onCheckAllLists: () => console.debug('not init'),

  loading: false,

  search: { ...DEFAULT_SEARCH },
  setSearch: () => new Promise(() => console.debug('not init')),

  createList: () => new Promise(() => console.debug('not init')),

  copyList: () => new Promise(() => console.debug('not init')),
  updateList: () => new Promise(() => console.debug('not init')),

  copyLists: () => new Promise(() => console.debug('not init')),
  updateLists: () => new Promise(() => console.debug('not init')),

  deleteLists: () => new Promise(() => console.debug('not init')),

  renameFolder: () => new Promise(() => console.debug('not init')),

  updatePitches: () => new Promise(() => console.debug('not init')),

  deletePitches: () => new Promise(() => console.debug('not init')),

  refreshActive: () => console.debug('not init'),
  refreshLists: () => console.debug('not init'),

  uploadCSV: async () => new Promise(() => console.debug('not init')),
  uploadAvatar: async () => new Promise(() => console.debug('not init')),

  activeReadOnly: () => false,
  updateActiveTrainingStatus: () => console.debug('not init'),
  rebuildFromActive: () => new Promise(() => console.debug('not init')),
};

export const PitchListsContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  children: ReactNode;
}

export const PitchListsProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState<Date | undefined>(undefined);
  const [_search, _setSearch] = useState<ISearchPitches>(DEFAULT.search);

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_list, _setList] = useState(DEFAULT.active);

  const [_recentIDs, _setRecentIDs] = useState(DEFAULT.recentIDs);
  const [_lists, _setLists] = useState(DEFAULT.lists);
  const [_options, _setOptions] = useState(DEFAULT.options);
  const [_rawLists, _setRawLists] = useState(DEFAULT.lists);

  const [_pitches, _setPitches] = useState(DEFAULT.activePitches);

  /** updates matching dictionary first before actually updating _activePitches and returning the updated pitches */
  const _safeSetActivePitches = async (
    pitches: IPitch[],
    skipMatching?: boolean
  ): Promise<IPitch[]> => {
    if (pitches.length === 0) {
      _setPitches([]);
      return [];
    }

    // if any pitch is missing any hashes (unlikely but possible), fill them in temporarily
    pitches
      .filter((p) => MachineHelper.needsMSDictHash(p))
      .forEach((p) => {
        const ms = getMSFromMSDict(p, props.machineCx.machine).ms;

        if (!ms) {
          return;
        }

        ms.matching_hash = MachineHelper.getMSHash('matching', ms);
        ms.full_hash = MachineHelper.getMSHash('full', ms);
      });

    if (!skipMatching) {
      await props.matchingCx.updatePitches({
        pitches: pitches,
        includeHitterPresent: false,
        includeLowConfidence: true,
      });
    }

    _setPitches(pitches);

    return pitches;
  };

  /** assumes that pitch lists (that the user can access) have been loaded */
  const _changeActive = async (config: {
    trigger: string;
    list_id?: string;
    search?: ISearchPitches;
  }) => {
    try {
      if (_loading) {
        return;
      }

      if (config.list_id === SEARCH_ID) {
        if (!config.search) {
          return;
        }

        /** reset list contents before starting load */
        _safeSetActivePitches([]);

        const now = new Date();
        _setList({
          _id: SEARCH_ID,
          name: t('main.search'),
          folder: '',
          _created: now.toISOString(),
          _changed: now.toISOString(),
          super: false,
          _parent_def: '',
          _parent_id: '',
          _parent_field: '',
        });

        _setLoading(true);

        const pitches = await PitchesService.getInstance().searchPitches(
          config.search,
          config.search.limit
        );

        await _safeSetActivePitches(pitches, USE_AGG_SHOT_DICT);

        _setLoading(false);
        return;
      }

      const nextActive = _lists.find((l) => l._id === config.list_id);

      if (config.list_id && !nextActive && _lists.length > 0) {
        /** warning should show if lists is loaded but doesn't contain the target
         * e.g. trying to use a URL for a list that the user shouldn't access
         * e.g. user reassigned their active list and it's no longer accessible
         */
        NotifyHelper.warning({
          message_md: `You do not have access to list \`${config.list_id}\`.`,
        });
        props.sectionsCx.tryChangeSection({
          trigger: 'no access to pitch list',
          name: SectionName.PitchDesign,
        });
      }

      /** reset list contents before starting load */
      _safeSetActivePitches([]);
      _setList(nextActive);

      if (!nextActive) {
        return;
      }

      /** keep track of the most recently activated pitch lists */
      const recent = _recentIDs.filter((id) => id !== nextActive._id);
      recent.splice(0, 0, nextActive._id);

      /** only keep track of the top X most recent lists */
      _setRecentIDs(recent.filter((_, i) => i < RECENT_LENGTH));

      _setLoading(true);

      const pitches = await PitchesService.getInstance().getListPitches(
        nextActive._id,
        config.search
      );

      const safePitches = await _safeSetActivePitches(
        pitches,
        USE_AGG_SHOT_DICT
      );

      _setLoading(false);

      if (!props.matchingCx.aggReady) {
        return;
      }

      // as a post-processing, update the active list's overall training status
      const untrained = safePitches.filter(
        (p) => !props.matchingCx.isPitchTrained(p)
      ).length;

      const machineID = props.machineCx.machine.machineID;

      const dict = PitchListHelper.getTrainingDict({
        machineID: machineID,
        current: nextActive.training,
        total: safePitches.length,
        untrained: untrained,
      });

      if (dict[machineID] !== nextActive.training?.[machineID]) {
        PitchListsService.getInstance().putList({
          _id: nextActive._id,
          training: dict,
        });
      }
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was an error while preparing your pitch list. Please try again.',
      });
    }
  };

  const _updateList = async (config: {
    payload: Partial<IPitchList>;
    successMsg?: string;
    silently?: boolean;
  }) => {
    try {
      if (!config.silently) {
        _setLoading(true);
      }

      const result = await PitchListsService.getInstance()
        .putList(config.payload)
        .finally(() => {
          if (!config.silently) {
            _setLoading(false);
          }
        });

      if (!result.success) {
        if (!config.silently) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              `There was an error updating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
          });
        }
        return;
      }

      const updated = result.data as IPitchList;

      if (!updated) {
        if (!config.silently) {
          NotifyHelper.error({
            message_md: 'Server responded with an empty result.',
          });
        }
        return;
      }

      const index = _lists.findIndex((l) => l._id === updated._id);
      if (index !== -1) {
        /** replace pitch list in lists */
        const currentLists = _lists.filter((l) => l._id !== updated._id);
        currentLists.push(updated);
        _setRawLists(currentLists);
      }

      if (_list && _list._id === updated._id) {
        _setList(updated);
      }

      if (!config.silently) {
        NotifyHelper.success({
          message_md: config.successMsg ?? 'Pitch list updated!',
        });
      }

      return updated;
    } catch (e) {
      console.error(e);

      if (!config.silently) {
        NotifyHelper.error({
          message_md: `There was an unexpected error updating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });
      }
    }
  };

  const state: IPitchListsContext = {
    loading: _loading,

    search: _search,
    setSearch: async (payload) => {
      if (payload) {
        const safePayload: ISearchPitches = {
          ...payload,
          limit: MAX_SEARCH_LIMIT,
        };

        _setSearch(safePayload);
        await _changeActive({
          trigger: 'update search',
          list_id: _list?._id,
          search: safePayload,
        });
        return;
      }

      if (_list?._id === SEARCH_ID) {
        _setSearch({
          ...DEFAULT_SEARCH,
          limit: MAX_SEARCH_LIMIT,
        });
        await _changeActive({
          trigger: 'search results > reset search',
          list_id: _list?._id,
          search: { ...DEFAULT_SEARCH },
        });
        return;
      }

      _setSearch({
        ...DEFAULT_SEARCH,
        limit: MAX_SEARCH_LIMIT,
      });
      await _changeActive({
        trigger: 'pitch list > reset search',
        list_id: _list?._id,
      });
    },

    active: _list,
    activePitches: _pitches,

    onCheckOneList: () => {
      _setLists([..._lists]);
    },
    onCheckAllLists: (mode) => {
      _lists.forEach((m) => {
        m._checked = mode === 'all';
      });

      _setLists([..._lists]);
    },

    recentIDs: _recentIDs,
    lists: _lists,
    options: _options,

    deleteLists: async (ids) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance().deleteLists(ids);

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: `Pitch ${ids.length === 1 ? 'list' : 'lists'} deleted!`,
        });

        setTimeout(() => {
          if (_list && ids.includes(_list._id)) {
            _changeActive({ trigger: 'lists context, delete lists' });
          }

          /** remove deleted lists from session */
          const currentLists = _lists.filter((l) => !ids.includes(l._id));
          _setRawLists(currentLists);
        }, 500);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    updateList: _updateList,

    updateLists: async (payload, successMsg) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance().putLists(payload);
        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Pitch lists updated!',
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error updating your pitch lists. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    renameFolder: async (payload, successMsg) => {
      try {
        _setLoading(true);

        const result =
          await PitchListsService.getInstance().renameFolder(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Folder renamed!',
        });

        /** triggers reload of rawLists => lists */
        _setLastFetched(new Date());

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error renaming your folder. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    createList: async (payload) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance().postList(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const newList = result.data as IPitchList;
        const currentLists = [..._lists];
        currentLists.push(newList);

        _setRawLists(currentLists);

        NotifyHelper.success({
          message_md: `Pitch list "${newList.name}" created!`,
        });

        return newList;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error creating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    copyList: async (payload) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance().copyList(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const list = result.data as IPitchList;

        const newLists = [..._lists, list];

        _setRawLists(newLists);

        NotifyHelper.success({
          message_md: `Pitch list "${list.name}" copied!`,
        });

        return list;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error copying your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    copyLists: async (payload, successMsg) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance().copyLists(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Pitch lists copied!',
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error copying your pitch lists. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    deletePitches: async (ids) => {
      try {
        _setLoading(true);

        const result = await PitchesService.getInstance().deletePitches(ids);

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: `${ids.length === 1 ? 'Pitch' : 'Pitches'} deleted!`,
        });

        setTimeout(() => {
          const currentPitches = _pitches.filter((p) => !ids.includes(p._id));
          _safeSetActivePitches(currentPitches, true);
        }, 500);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error deleting your pitch. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    updatePitches: async (config) => {
      try {
        if (config.payloads.find((p) => !p._id)) {
          NotifyHelper.error({
            message_md: 'Cannot update a pitch with an empty ID.',
          });
          return undefined;
        }

        _setLoading(true);

        const result = await PitchesService.getInstance().putPitches(
          config.payloads
        );

        if (!result.success) {
          throw new Error(result.error);
        }

        const workingPitches = config.silently ? _pitches : [..._pitches];

        const updatedPitches = result.data as IPitch[];

        const changed = updatedPitches.map((updated) => {
          const index = workingPitches.findIndex((m) => m._id === updated._id);

          if (index === -1) {
            return false;
          }

          const original = workingPitches[index];

          // preserve checked value since it is scrubbed by server for CRUD
          updated._checked = original._checked;

          // replace the item inline
          workingPitches.splice(index, 1, updated);

          return true;
        });

        /** if necessary, update activePitches */
        if (changed.includes(true)) {
          _safeSetActivePitches(workingPitches);

          if (config.successMsg) {
            NotifyHelper.success({ message_md: config.successMsg });
          }
        }

        return updatedPitches;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error updating your ${
                  config.payloads.length === 1 ? 'pitch' : 'pitches'
                }.`,
        });

        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    refreshActive: () => {
      if (_list) {
        _setLoading(true);

        if (_list._id === SEARCH_ID) {
          if (!_search) {
            return;
          }

          PitchesService.getInstance()
            .searchPitches(_search, MAX_SEARCH_LIMIT)
            .then((pitches) => _safeSetActivePitches(pitches))
            .catch(console.error)
            .finally(() => _setLoading(false));
          return;
        }

        PitchesService.getInstance()
          .getListPitches(_list._id, _search)
          .then((pitches) => _safeSetActivePitches(pitches))
          .catch(console.error)
          .finally(() => _setLoading(false));
      }
    },

    refreshLists: (notify) => {
      if (notify) {
        NotifyHelper.success({ message_md: 'Refreshing pitch lists...' });
      }
      _setLastFetched(new Date());
    },

    uploadCSV: async (files) => {
      try {
        if (!_list) {
          NotifyHelper.error({
            message_md: 'Cannot import file without an active pitch list.',
          });
          return false;
        }

        if (_list._id === SEARCH_ID) {
          NotifyHelper.error({
            message_md: 'Cannot import file while searching pitches.',
          });
          return false;
        }

        _setLoading(true);

        await PitchListsService.getInstance().importCSV(_list._id, files);

        _setLastFetched(new Date());

        return true;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        _setLoading(false);
      }
    },

    uploadAvatar: async (list_id, files, onProgress) => {
      try {
        _setLoading(true);

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('files', f);
        });

        const result = await PitchListsService.getInstance().postCardAvatar(
          list_id,
          formData,
          onProgress
        );

        if (!result.success) {
          throw new Error(result.error);
        }

        _setLastFetched(new Date());

        return result.data as IPitchList;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : 'There was an error uploading your image.',
        });

        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    activeReadOnly: () => {
      if (!_list) {
        return false;
      }

      if (props.authCx.current.role === UserRole.admin) {
        return false;
      }

      if (_list.type && READ_ONLY_LIST_TYPES.includes(_list.type)) {
        return true;
      }

      return false;
    },

    updateActiveTrainingStatus: () => {
      if (!_list) {
        return;
      }

      if (_list._id === SEARCH_ID) {
        return;
      }

      if (!props.matchingCx.aggReady) {
        return;
      }

      const dict = PitchListHelper.getTrainingDict({
        machineID: props.machineCx.machine.machineID,
        current: _list.training,
        total: _pitches.length,
        untrained: _pitches.filter((p) => !props.matchingCx.isPitchTrained(p))
          .length,
      });

      _updateList({
        payload: {
          _id: _list._id,
          training: dict,
        },
        silently: true,
      });
    },

    rebuildFromActive: async (ids) => {
      try {
        _setLoading(true);

        const pitches = _pitches.filter((p) => ids.includes(p._id));
        if (pitches.length === 0) {
          return;
        }

        NotifyHelper.success({
          message_md: `${
            pitches.length === 1 ? 'One pitch' : `${pitches.length} pitches`
          } will be refreshed using your latest model to optimize replication accuracy!`,
        });

        const refreshed =
          await StateTransformService.getInstance().forceRefreshPitches({
            pitches: pitches,
            ms: true,
            traj: true,
          });

        refreshed.forEach((rp) => {
          const index = _pitches.findIndex((ap) => ap._id === rp._id);
          if (index !== -1) {
            _pitches.splice(index, 1, rp);
          }
        });

        // skip matching because these would be newly built pitches anyway
        _safeSetActivePitches([..._pitches], true);
      } catch (e) {
        console.error(e);
      } finally {
        _setLoading(false);
      }
    },
  };

  /** reload the data whenever machineID changes to get relevant machine-only lists */
  useEffect(() => {
    if (_lastFetched) {
      (async (): Promise<void> => {
        _setLists([]);

        _setLoading(true);
        PitchListsService.getInstance()
          .getVisible(props.authCx.current.role, props.authCx.current.mode)
          .then((result) => {
            if (!result) {
              _setRawLists([]);
              return;
            }

            const filteredResult = result.filter((l) => {
              if (l.type) {
                return true;
              }

              switch (l._parent_def) {
                case 'teams': {
                  return props.authCx.current.team_lists;
                }

                case 'team-machines': {
                  return props.authCx.current.machine_lists;
                }

                /** always allow personal lists */
                case 'team-users': {
                  return true;
                }

                /** suppress any malformed entries */
                default: {
                  return false;
                }
              }
            });

            _setRawLists(filteredResult);
            return;
          })
          .finally(() => _setLoading(false));
      })();
    }
  }, [
    _lastFetched,
    /** anything that might result in different pitch mss should trigger a reload */
    props.machineCx.machine.machineID,
    props.machineCx.machine.ball_type,
    props.authCx.current.session,
    props.authCx.current.role,
    props.authCx.current.mode,
    props.authCx.current.team_lists,
    props.authCx.current.machine_lists,
  ]);

  useEffect(() => {
    if (props.sectionsCx.active.name === SectionName.Search) {
      _setSearch({ ...DEFAULT_SEARCH });
      _changeActive({
        trigger: 'lists context, search pitches',
        list_id: SEARCH_ID,
        search: { ...DEFAULT_SEARCH },
      });
      return;
    }

    if (_lists.length === 0) {
      _changeActive({
        trigger: 'lists context, no lists loaded',
        list_id: undefined,
      });
      return;
    }

    if (!props.authCx.current.auth) {
      _changeActive({
        trigger: 'lists context, not authenticated',
        list_id: undefined,
      });
      return;
    }

    if (props.sectionsCx.active.name !== SectionName.PitchList) {
      _changeActive({
        trigger: 'lists context, active section changed',
        list_id: undefined,
      });
      return;
    }

    if (!props.sectionsCx.active.fragment) {
      _changeActive({
        trigger: 'lists context, no route fragment for list_id',
        list_id: undefined,
      });
      return;
    }

    if (props.sectionsCx.active.fragment !== _list?._id) {
      _changeActive({
        trigger: 'lists context, detected route fragment',
        list_id: props.sectionsCx.active.fragment,
      });
      return;
    }
  }, [
    _lists,
    props.authCx.current.auth,
    props.sectionsCx.active.name,
    props.sectionsCx.active.fragment,
  ]);

  /** detect special session mode, reload data to match user's access */
  useEffect(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (props.authCx.current.auth) {
      _setLastFetched(new Date());
    }
  }, [props.authCx.current.auth, props.authCx.current.session]);

  /** refresh the list (which will populate msDict if necessary) whenever machine changes */
  useEffect(() => {
    if (_list) {
      _changeActive({
        trigger: 'machine context changed',
        list_id: _list._id,
      });
    }
  }, [props.machineCx.machine.machineID, props.machineCx.machine.ball_type]);

  /** automatically clean up recent IDs and the folders from rawLists before setting lists for use */
  useEffect(() => {
    /** e.g. a list is deleted, should not show up in recent lists anymore */
    const rawIDs = _rawLists.map((l) => l._id);
    _setRecentIDs(_recentIDs.filter((id) => rawIDs.includes(id)));

    const cleanLists = sanitizeFolders(_rawLists);
    _setLists(cleanLists);

    _setOptions({
      name: ArrayHelper.unique(cleanLists.map((l) => l.name)).sort(
        (a: string, b: string) => (a ?? '').localeCompare(b ?? '')
      ),

      status: Object.values(TrainingStatus),

      _created: ArrayHelper.unique(cleanLists.map((l) => l._created))
        .map((a: string) => lightFormat(parseISO(a), 'yyyy-MM-dd'))
        .sort((a: string, b: string) => a.localeCompare(b)),
    });
  }, [_rawLists, props.machineCx.machine.machineID]);

  /** keep active synced with whatever is in lists, e.g. if its folder is renamed */
  useEffect(() => {
    if (_list) {
      const listActive = _lists.find((l) => l._id === _list._id);
      if (listActive && _list !== listActive) {
        _setList(listActive);
      }
    }
  }, [_list, _lists]);

  return (
    <PitchListsContext.Provider value={state}>
      {props.children}
    </PitchListsContext.Provider>
  );
};
