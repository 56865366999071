import { Box, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IQueueMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'QueueListener';

export const DEFAULT_WAIT_SECONDS = 3;

interface IProps {
  machineCx: IMachineContext;
}

interface IState {
  dialog: boolean;

  waiting_user?: string;
  waiting_session?: string;

  seconds_remaining: number;
}

export class QueueListener extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dialog: false,
      seconds_remaining: 0,
    };

    this.concludeGrantControl = this.concludeGrantControl.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleControlRequest = this.handleControlRequest.bind(this);
    this.handleControlResponse = this.handleControlResponse.bind(this);
    this.sendResponse = this.sendResponse.bind(this);
  }

  private grantControlInterval: any;

  componentDidMount() {
    WebSocketHelper.on(
      WsMsgType.Misc_ControlRequest,
      this.handleControlRequest
    );
    WebSocketHelper.on(
      WsMsgType.Misc_ControlResponse,
      this.handleControlResponse
    );
  }

  componentWillUnmount() {
    clearInterval(this.grantControlInterval);

    WebSocketHelper.remove(
      WsMsgType.Misc_ControlRequest,
      this.handleControlRequest
    );
    WebSocketHelper.remove(
      WsMsgType.Misc_ControlResponse,
      this.handleControlResponse
    );
  }

  private concludeGrantControl(reason: string) {
    console.debug(`clearing control interval (${reason})`);

    clearInterval(this.grantControlInterval);
    this.grantControlInterval = undefined;

    this.setState({
      dialog: false,
      seconds_remaining: 0,
    });
  }

  private handleControlResponse(event: CustomEvent) {
    const data: IQueueMsg = event.detail;

    switch (data.action) {
      case '2fa-accept': {
        NotifyHelper.success({ message_md: '2FA code accepted!' });
        break;
      }

      case '2fa-reject': {
        NotifyHelper.warning({
          message_md: 'Incorrect 2FA code, please try again!',
        });
        break;
      }

      default: {
        break;
      }
    }
  }

  private handleControlRequest(event: CustomEvent) {
    const data: IQueueMsg = event.detail;

    switch (data.action) {
      case 'waiting-request': {
        if (this.state.dialog) {
          /** already open from a previous request, waiting for user input */
          NotifyHelper.info({
            message_md: `Access request from ${
              data.waiting_user ?? 'unknown user'
            } was automatically declined (waiting on response to ${
              this.state.waiting_user
            }).`,
          });

          this.sendResponse({
            accepted: false,
            conclude: false,
            username: data.waiting_user ?? 'unknown user',
            session: data.waiting_session ?? 'unknown session',
          });
          return;
        }

        /** wait for accept/decline control request input */
        this.setState(
          {
            dialog: true,

            waiting_user: data.waiting_user,
            waiting_session: data.waiting_session,
            seconds_remaining: DEFAULT_WAIT_SECONDS,
          },
          () => {
            if (this.grantControlInterval) {
              // don't restart the interval before it concludes
              return;
            }

            this.grantControlInterval = setInterval(() => {
              const nextRemaining = this.state.seconds_remaining - 1;
              this.setState({ seconds_remaining: nextRemaining }, () => {
                /** only auto-accept if dialog was still when timeout tripped */
                if (nextRemaining <= 0) {
                  /** timeout is over, close the dialog (cleanup), requester's side will have taken control */
                  this.concludeGrantControl('timeout');
                }
              });
            }, 1_000);
          }
        );
        return;
      }

      default: {
        return;
      }
    }
  }

  private sendResponse(config: {
    username: string;
    session: string;
    accepted: boolean;
    conclude: boolean;
  }) {
    /** closing from the header will be counted as declining */
    this.props.machineCx.sendControlResponse({
      action: config.accepted ? 'active-accept' : 'active-reject',
      waiting_user: config.username,
      waiting_session: config.session,
    });

    if (config.conclude) {
      this.concludeGrantControl(
        config.accepted ? 'active-accept' : 'active-reject'
      );
    }
  }

  private handleClose(accepted: boolean) {
    this.sendResponse({
      accepted: accepted === true,
      conclude: true,
      username: this.state.waiting_user ?? '(no user)',
      session: this.state.waiting_session ?? '(no session)',
    });
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        {this.state.dialog && (
          <CommonDialog
            identifier={COMPONENT_NAME}
            width={RADIX.DIALOG.WIDTH.MD}
            title="common.machine-control-request"
            content={
              <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                <Box>
                  <ReactMarkdown
                    children={t('common.machine-control-request-msg', {
                      machineID: this.props.machineCx.machine.machineID,
                      username: this.state.waiting_user,
                      seconds: this.state.seconds_remaining,
                    })}
                  />
                </Box>
              </Flex>
            }
            buttons={[
              {
                label: 'common.relinquish-control',
                color: RADIX.COLOR.WARNING,
                onClick: () => this.handleClose(true),
              },
              {
                label: 'common.stay-active',
                color: RADIX.COLOR.WARNING,
                onClick: () => this.handleClose(false),
              },
            ]}
            onClose={() => this.handleClose(false)}
          />
        )}
      </ErrorBoundary>
    );
  }
}
