import {
  ChevronUpIcon,
  Cross1Icon,
  MagnifyingGlassIcon,
} from '@radix-ui/react-icons';
import {
  Box,
  ChevronDownIcon,
  Flex,
  Popover,
  Text,
  TextField,
} from '@radix-ui/themes';
import { CommonChecklist } from 'components/common/checklist';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ISearchInput } from 'interfaces/forms/search';
import { ISelectBase } from 'interfaces/forms/select';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';
import slugify from 'slugify';

const AUTO_CLOSE_POPOVER = true;
const AUTO_CLEAR_SEARCH = false;

interface ISearchOption extends IOption {
  searchKeys: string[];
}

const keyify = (v: string) => {
  const values = slugify(v, {
    lower: true,
    strict: true,
  }).split('-');

  return ArrayHelper.unique(values);
};

const buildGroupedOptions = (config: ISelectBase): ISearchOption[] => {
  const output: ISearchOption[] = [];

  const grouped = ArrayHelper.groupBy(config.options, 'group');

  Object.keys(grouped)
    .sort()
    .forEach((key) => {
      const group = grouped[key];

      if (group.length === 0) {
        return;
      }

      if (key) {
        output.push({
          label: key,
          value: key,
          group: key,
          disabled: true,
          hideControl: true,
          // for strict matching to find the full, unchanged group
          searchKeys: [key, ...keyify(key)],
        });
      }

      if (!config.skipSort) {
        group.sort((a, b) =>
          (config.reverseSort ? b : a).label.localeCompare(
            (config.reverseSort ? a : b).label
          )
        );
      }

      output.push(
        ...group.map((m) => {
          const o: ISearchOption = {
            ...m,
            searchKeys: [
              // for strict matching to find the full, unchanged value
              m.value,
              ...keyify(
                [m.value, m.label, m.group ?? '', ...(m.aliases ?? [])].join(
                  ' '
                )
              ),
            ],
          };

          return o;
        })
      );
    });

  return output;
};

interface IState {
  key: number;
  open: boolean;
  searchTerm: string;

  options: ISearchOption[];
}

export class CommonSearchInput extends React.Component<ISearchInput, IState> {
  private trigger?: HTMLInputElement;

  constructor(props: ISearchInput) {
    super(props);

    this.state = {
      key: Date.now(),
      open: false,
      searchTerm: '',
      options: buildGroupedOptions(props),
    };

    this.getSlotIcon = this.getSlotIcon.bind(this);
    this.getValueDisplay = this.getValueDisplay.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<ISearchInput>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (!ArrayHelper.equals(prevProps.options, this.props.options)) {
      // console.debug('search rebuilt from componentDidUpdate');
      this.setState({
        options: buildGroupedOptions(this.props),
      });
    }
  }

  reset() {
    // this forces the component to redraw from scratch
    this.setState({
      key: Date.now(),
      options: buildGroupedOptions(this.props),
    });
  }

  private getValueDisplay() {
    if (!this.props.values) {
      return '';
    }

    if (this.props.values.length === 0) {
      return '';
    }

    if (this.props.values.length === 1) {
      const firstValue = this.props.values[0];
      return (
        this.props.options.find((o) => o.value === firstValue)?.label ??
        firstValue
      );
    }

    return `${this.props.values.length} options selected`;
  }

  private getSlotIcon() {
    if (this.props.disabled) {
      // don't show any icon since the user can't use it anyway
      return;
    }

    if (
      this.props.optional &&
      this.props.values &&
      this.props.values.length > 0
    ) {
      return (
        <Cross1Icon
          onClick={() => {
            if (this.props.disabled) {
              return;
            }

            this.props.onChange([]);
          }}
        />
      );
    }

    if (this.state.open) {
      return <ChevronUpIcon />;
    }

    return (
      <ChevronDownIcon
        onClick={() => {
          if (this.props.disabled) {
            return;
          }

          this.setState({ open: true });
        }}
      />
    );
  }

  render() {
    const placeholder = t(
      this.props.placeholder ??
        (this.props.hideSearch
          ? 'common.select-placeholder'
          : 'common.search-placeholder')
    ).toString();

    const safeSearchTerms = this.props.strict
      ? [this.state.searchTerm]
      : keyify(this.state.searchTerm);

    const matchingOptions = this.state.options.filter(
      (o) =>
        safeSearchTerms.length === 0 ||
        ArrayHelper.hasSubstringIntersection(o.searchKeys, safeSearchTerms)
    );

    return (
      <ErrorBoundary componentName="CommonSearchInput">
        <CommonInputWrapper {...this.props}>
          <CommonInputLabel {...this.props} />

          <Popover.Root
            open={this.state.open}
            onOpenChange={(value) => {
              this.setState({ open: value });

              if (AUTO_CLEAR_SEARCH && !value) {
                // clear search on popover close
                this.setState({ searchTerm: '' });
              }
            }}
          >
            <Popover.Trigger disabled={this.props.disabled}>
              <TextField.Root
                key={`trigger-${this.state.key}`}
                ref={(elem) => (this.trigger = elem as HTMLInputElement)}
                className={`cursor-pointer ${this.props.className ?? ''}`}
                disabled={this.props.disabled}
                color={this.props.inputColor}
                name={this.props.name}
                placeholder={placeholder}
                required={!this.props.optional}
                value={this.getValueDisplay()}
                onChange={() => {
                  // onChange provided to suppress error re: value w/o onChange
                }}
                // prevent the cursor from entering this input (on click or clear) so users don't think they can type into it
                onFocus={() => this.trigger?.blur()}
                type="text"
              >
                <TextField.Slot side="right">
                  {this.getSlotIcon()}
                </TextField.Slot>
              </TextField.Root>
            </Popover.Trigger>

            <Popover.Content minWidth="300px">
              {/* for performance, don't draw the stuff unless the search is open */}
              {this.state.open && (
                <Flex direction="column" gap={RADIX.FLEX.GAP.INPUT}>
                  {!this.props.hideSearch && (
                    <Box>
                      <TextField.Root
                        key={`search-${this.state.key}`}
                        className={this.props.className}
                        disabled={this.props.disabled}
                        name={this.props.name}
                        type="text"
                        value={this.state.searchTerm}
                        onChange={(e) => {
                          const v = e.target.value;
                          this.setState({ searchTerm: v });
                        }}
                      >
                        <TextField.Slot side="right">
                          {this.state.searchTerm ? (
                            <Cross1Icon
                              className="cursor-pointer"
                              onClick={() => this.setState({ searchTerm: '' })}
                            />
                          ) : (
                            <MagnifyingGlassIcon />
                          )}
                        </TextField.Slot>
                      </TextField.Root>
                    </Box>
                  )}

                  <Box
                    maxHeight="250px"
                    maxWidth="40ch"
                    overflowX="hidden"
                    overflowY="scroll"
                  >
                    <CommonChecklist
                      id={`${this.props.id}-search-checklist`}
                      options={matchingOptions}
                      onChange={(v) => {
                        this.props.onChange(v);

                        if (AUTO_CLOSE_POPOVER && !this.props.multiple) {
                          this.setState({ open: false });
                        }
                      }}
                      values={this.props.values ?? []}
                      as={this.props.multiple ? 'checkbox' : 'button'}
                    />
                  </Box>

                  {matchingOptions.length === 0 && (
                    <Box>
                      <Text>{t('common.no-results-found')}</Text>
                    </Box>
                  )}
                </Flex>
              )}
            </Popover.Content>
          </Popover.Root>

          <CommonInputHint {...this.props} />
        </CommonInputWrapper>
      </ErrorBoundary>
    );
  }
}
