import { RouteHelper } from 'classes/helpers/route.helper';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { getRouteDict, SectionName } from 'enums/route.enums';
import { ISection } from 'interfaces/i-section';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { GAME_STATUS_BLACKLIST } from 'lib_ts/enums/mlb.enums';
import { DEFAULT_COMBINED_PERMISSIONS } from 'lib_ts/interfaces/i-permissions';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export enum DirtyForm {
  PitchDesign,
  PitchUploader,
  VideoLibrary,
}

const ROUTE_DICT = getRouteDict();

interface IChangeSectionConfig extends ISectionDef {
  trigger: string;
  beforeNavCallback?: () => Promise<void>;
}

interface ISectionDef {
  name: SectionName;
  /** e.g. pitch list _id */
  fragment?: string;
}

export interface ISectionsContext {
  active: ISectionDef;
  userSections: ISection[];
  adminSections: ISection[];

  dirtyForms: DirtyForm[];
  readonly markDirtyForm: (context: DirtyForm) => void;
  readonly clearDirtyForm: (context: DirtyForm) => void;

  readonly tryChangeSection: (config: IChangeSectionConfig) => boolean;
}

const DEFAULT: ISectionsContext = {
  active: { name: SectionName.Home },
  userSections: SectionsHelper.getUserSections({
    restricted: false,
    permissions: DEFAULT_COMBINED_PERMISSIONS,
  }),
  adminSections: [],

  dirtyForms: [],
  markDirtyForm: () => console.debug('not init'),
  clearDirtyForm: () => console.debug('not init'),

  tryChangeSection: () => false,
};

export const SectionsContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  children: ReactNode;
}

export const SectionsProvider: FC<IProps> = (props) => {
  const [_userSections, _setUserSections] = useState(DEFAULT.userSections);
  const [_adminSections, _setAdminSections] = useState(DEFAULT.adminSections);

  const [_active, _setActive] = useState(DEFAULT.active);
  const [_pendingConfig, _setPendingConfig] = useState<
    IChangeSectionConfig | undefined
  >(undefined);

  const [_dirtyForms, _setDirtyForms] = useState(DEFAULT.dirtyForms);
  const [_confirm, _setConfirm] = useState<number | undefined>();

  const location = useLocation();
  const navigate = useNavigate();

  const _changeSection = async (config: IChangeSectionConfig) => {
    if (config.beforeNavCallback) {
      await config.beforeNavCallback();
    }

    /** clear the dirty contexts whenever changing sections so it doesn't get stuck */
    _setDirtyForms([]);

    /** attempt to start screensaver (only if moving to different section, e.g. list to list won't trigger this) */
    if (props.machineCx.machine.enable_auto_reset_ms) {
      props.machineCx.specialMstarget(SpecialMsPosition.lowered);
    }

    _setActive({ name: config.name, fragment: config.fragment });

    navigate(RouteHelper.section(config.name, config.fragment));
  };

  const _tryChangeSection = (config: IChangeSectionConfig): boolean => {
    if (_dirtyForms.length === 0) {
      _changeSection(config);
      return true;
    }

    /** store the selection */
    _setPendingConfig(config);

    /** show confirmation */
    _setConfirm(Date.now());

    return false;
  };

  const state: ISectionsContext = {
    active: _active,

    userSections: _userSections,
    adminSections: _adminSections,

    dirtyForms: _dirtyForms,
    markDirtyForm: (context) =>
      _setDirtyForms(ArrayHelper.unique([..._dirtyForms, context])),
    clearDirtyForm: (context) =>
      _setDirtyForms(_dirtyForms.filter((c) => c !== context)),

    tryChangeSection: _tryChangeSection,
  };

  /** detect section from route at launch,  */
  useEffect(() => {
    /**
     * 0-index => empty, since the paths start with /
     * 1-index => section name, slugified
     * 2-index => (optional) fragment(s)
     */
    const locationParts = location.pathname.split('/');

    const section = locationParts[1];
    const fragment = locationParts[2];

    _tryChangeSection({
      trigger: 'from location at startup',
      name: ROUTE_DICT[`/${section}`],
      fragment: fragment,
    });
  }, []);

  /** detect if impersonated user has rights to access the current section */
  useEffect(() => {
    if (props.authCx.current.mode !== 'impostor') {
      // skip if the user isn't impersonating
      return;
    }

    if (
      location.pathname.startsWith(RouteHelper.section(SectionName.PitchList))
    ) {
      // pitch list ends with the id and is never forbidden anyway
      return;
    }

    const sectionName = ROUTE_DICT[location.pathname];
    const section = [..._userSections, ..._adminSections].find(
      (s) => s.value === sectionName
    );

    if (!section) {
      _tryChangeSection({
        trigger: `attempted to access invalid/restricted section via ${
          location.pathname
        }: ${sectionName ?? '(unknown section)'}`,
        name: SectionName.PitchDesign,
      });
    }
  }, [props.authCx.current.mode]);

  /** update sidebar options and potentially active section whenever user role and/or game status changes */
  useEffect(() => {
    const userOptions = SectionsHelper.getUserSections({
      permissions: props.authCx.current,
      restricted: props.authCx.restrictedGameStatus(),
    });
    _setUserSections(userOptions);

    const adminOptions = SectionsHelper.getAdminSections({
      role: props.authCx.current.role,
      restricted: props.authCx.restrictedGameStatus(),
      mode: props.authCx.current.mode,
    });
    _setAdminSections(adminOptions);

    if (
      [...userOptions, ...adminOptions].findIndex(
        (s) => !s.invisible && s.value === _active.name
      ) !== -1
    ) {
      /** active section is still available */
      return;
    }

    if (
      props.authCx.gameStatus &&
      GAME_STATUS_BLACKLIST.includes(props.authCx.gameStatus)
    ) {
      _tryChangeSection({
        trigger: `user on section ${_active} redirected due to game status ${props.authCx.gameStatus}`,
        name: SectionName.GameInProgress,
      });
      return;
    }
  }, [props.authCx.current, props.authCx.gameStatus]);

  return (
    <SectionsContext.Provider value={state}>
      {props.children}

      {_confirm && (
        <CommonConfirmationDialog
          // every new value of _confirm causes the alert dialog to re-mount
          key={_confirm}
          identifier="ConfirmChangeSectionDialog"
          title="common.warning"
          description="common.unsaved-changes-msg"
          action={{
            label: 'common.proceed',
            onClick: () => {
              _setDirtyForms([]);

              if (_pendingConfig) {
                _changeSection(_pendingConfig);
              }
            },
          }}
          cancel={{
            onClick: () => {
              // do nothing
            },
          }}
        />
      )}
    </SectionsContext.Provider>
  );
};
