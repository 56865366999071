import { TrainingHelper } from 'classes/helpers/training-helper';
import { LABEL_FONT, NEW_TRAINING_DOT, OLD_TRAINING_DOT } from 'enums/canvas';
import { PTStep, TrainStep } from 'enums/training.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { EllipseHelper, SZ_WIDTH_FT } from 'lib_ts/classes/ellipse.helper';
import {
  FT_TO_INCHES,
  METERS_TO_FT,
  METERS_TO_INCHES,
  RAD_FULL_ROTATION,
} from 'lib_ts/classes/math.utilities';
import { PlateHelper } from 'lib_ts/classes/plate.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { PlateH, PlateOutcome, PlateV } from 'lib_ts/enums/plate.enums';
import { IEllipse } from 'lib_ts/interfaces/i-ellipses';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import { IPlateConfig, IPlateSummary } from 'lib_ts/interfaces/i-plate-config';
import {
  DEFAULT_PLATE,
  IBallState,
  IPitch,
  IPlateLoc,
  ITrajectory,
} from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IPresetTrainingSpec } from 'lib_ts/interfaces/training/i-preset-training-spec';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';

/** home plate is 17 inches wide */
const SZ_GUTTER_FT = 2 / 12;

/** only auto-adjust shots that land within the strike zone + a certain buffer around */
const SZ_GREEN_BUFFER_FT = 7 / 12;

/** how much smaller to draw fill objects than the boxes they lie within
 * e.g. filling grids in the strike zone without covering the lines */
const INDENT_PX = 2;

/** do not complete training for a pitch until at least this many shots have been recorded, even if any given shot lands within the acceptable region */
export const MIN_QUICK_TRAIN_SHOTS = 3;
export const MIN_MANUAL_TRAIN_SHOTS = 1;

const INVISIBLE_COLOR = 'rgba(0,0,0,0)';
const LINE_COLOR = '#999999';
const BATTERS_BOX_COLOR = '#666666';
const HOME_PLATE_COLOR = '#999999';

const ALLOW_ADJUSTMENT = false;

export const MIN_CONFIDENCE = 0.9;

export const MAX_SHOTS_USED = 10;

export const DOT_RGB_ACTUAL = '255, 193, 6';
export const DOT_RGB_ROTATED = '25, 135, 84';
export const DOT_RGB_TEST = '49, 210, 242';

const BASE_BREAKS_SD_IN = 2.5;
const BASE_SPEED_SD_MPH = 0.4;
const BASE_SPIN_SD_RPM = 150;

const VERBOSE = false;

const LOW_PROBABILITY = 0.1;
const HIGH_PROBABILITY = 0.8;

interface IPreOptimize {
  prevStep: PTStep;
  spec: IPresetTrainingSpec;
  // 0-indexed
  currentIteration: number;
  summaryFn: <T extends { [value: string]: any }>(input: T[]) => Partial<T>;
  pitch: Partial<IPitch>;
  shot: IMachineShot | undefined;
  allShots: IMachineShot[];
}

interface IPostOptimize {
  prevStep: PTStep;
  pitch: Partial<IPitch>;
  shot: IMachineShot | undefined;
  allShots: IMachineShot[];
  threshold: number;
  requireConfidence: boolean;
}

const vDebug = (v: any) => (VERBOSE ? console.debug(v) : undefined);

export class PlateCanvas {
  static makeDefault() {
    return new PlateCanvas({
      aspectRatio: 300 / 390,
      height_px: 400,
      min_height_ft: -1.5,
      max_height_ft: 4.5,
    });
  }

  static makeSimple() {
    return new PlateCanvas({
      aspectRatio: 16 / 10,
      height_px: 400,
      min_height_ft: -1.5,
      max_height_ft: 5.5,
    });
  }

  static makeQuickSession() {
    return new PlateCanvas({
      aspectRatio: 300 / 390,
      height_px: 400,
      min_height_ft: 0.8,
      max_height_ft: 4.2,
      step: 0.01,
    });
  }

  readonly PLATE_CONFIG: IPlateConfig;

  private readonly SZ_PX: {
    top: number;
    bottom: number;
    left: number;
    right: number;
    middle_x: number;
    middle_y: number;
  };

  private readonly SZ_AUTO_ADJUST_PX: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };

  /** for gutter around strike zone (e.g. in quick session) */
  private readonly SZ_GUTTER_PX: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };

  private readonly HOME_PLATE_PX: {
    bottom: number;
    tip: number;
    left_line_inside: number;
    left_line_outside: number;
    right_line_inside: number;
    right_line_outside: number;
  };

  private readonly CONSTANTS_PX: {
    origin_x: number;
    origin_y: number;
  };

  constructor(config: {
    aspectRatio: number;
    height_px: number;
    min_height_ft: number;
    max_height_ft: number;
    step?: number;
  }) {
    const total_y_ft = config.max_height_ft - config.min_height_ft;
    const abs_x_ft = (total_y_ft * config.aspectRatio) / 2;

    this.PLATE_CONFIG = {
      strikeZone: {
        height_in: (3.6 - 1.5) * 12,
        left_ft: -SZ_WIDTH_FT / 2,
        bottom_ft: 1.5,
        right_ft: SZ_WIDTH_FT / 2,
        top_ft: 3.6,
      },
      canvas: {
        width_px: config.height_px * config.aspectRatio,
        height_px: config.height_px,
      },
      x: {
        min_ft: -abs_x_ft,
        max_ft: abs_x_ft,
        default_ft: 0,
        step: config.step ?? 0.00001,
      },
      y: {
        min_ft: config.min_height_ft,
        max_ft: config.max_height_ft,
        default_ft: 2.5,
        step: config.step ?? 0.00001,
      },
    };

    /** must be after plate is defined */
    this.CONSTANTS_PX = {
      origin_x: this.ftToPxX(0),
      origin_y: this.ftToPxY(0),
    };

    this.SZ_PX = {
      top: this.ftToPxY(this.PLATE_CONFIG.strikeZone.top_ft),
      bottom: this.ftToPxY(this.PLATE_CONFIG.strikeZone.bottom_ft),
      left: this.ftToPxX(this.PLATE_CONFIG.strikeZone.left_ft),
      right: this.ftToPxX(this.PLATE_CONFIG.strikeZone.right_ft),
      middle_x: this.ftToPxX(0),
      middle_y: this.ftToPxY(
        (this.PLATE_CONFIG.strikeZone.top_ft +
          this.PLATE_CONFIG.strikeZone.bottom_ft) /
          2
      ),
    };

    this.SZ_AUTO_ADJUST_PX = {
      top: this.ftToPxY(
        this.PLATE_CONFIG.strikeZone.top_ft + SZ_GREEN_BUFFER_FT
      ),
      bottom: this.ftToPxY(
        this.PLATE_CONFIG.strikeZone.bottom_ft - SZ_GREEN_BUFFER_FT
      ),
      left: this.ftToPxX(
        this.PLATE_CONFIG.strikeZone.left_ft - SZ_GREEN_BUFFER_FT
      ),
      right: this.ftToPxX(
        this.PLATE_CONFIG.strikeZone.right_ft + SZ_GREEN_BUFFER_FT
      ),
    };

    this.SZ_GUTTER_PX = {
      top: this.ftToPxY(this.PLATE_CONFIG.strikeZone.top_ft + SZ_GUTTER_FT),
      bottom: this.ftToPxY(
        this.PLATE_CONFIG.strikeZone.bottom_ft - SZ_GUTTER_FT
      ),
      left: this.ftToPxX(this.PLATE_CONFIG.strikeZone.left_ft - SZ_GUTTER_FT),
      right: this.ftToPxX(this.PLATE_CONFIG.strikeZone.right_ft + SZ_GUTTER_FT),
    };

    this.HOME_PLATE_PX = {
      bottom: this.ftToPxY(-8.5 / 12 / 2),
      tip: this.ftToPxY((-8.5 * 2) / 12 / 2),
      left_line_inside: this.ftToPxX(-29 / 12 / 2),
      left_line_outside: this.ftToPxX(-36.25 / 12 / 2),
      right_line_inside: this.ftToPxX(29 / 12 / 2),
      right_line_outside: this.ftToPxX(36.25 / 12 / 2),
    };
  }

  private strikeZoneGridHeight(unit: 'px' | 'ft') {
    if (unit === 'px') {
      return (this.SZ_PX.bottom - this.SZ_PX.top) / 3;
    }

    if (unit === 'ft') {
      return (
        (this.PLATE_CONFIG.strikeZone.top_ft -
          this.PLATE_CONFIG.strikeZone.bottom_ft) /
        3
      );
    }

    return 0;
  }

  private strikeZoneGridWidth(unit: 'px' | 'ft') {
    if (unit === 'px') {
      return (this.SZ_PX.right - this.SZ_PX.left) / 3;
    }

    if (unit === 'ft') {
      return (
        (this.PLATE_CONFIG.strikeZone.right_ft -
          this.PLATE_CONFIG.strikeZone.left_ft) /
        3
      );
    }

    return 0;
  }

  private ftToPxY(y_ft: number) {
    /** convert y_ft into a px value that works for the canvas by translating so that y.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(
      this.PLATE_CONFIG.y.max_ft - this.PLATE_CONFIG.y.min_ft
    );
    const y_perc = (y_ft - this.PLATE_CONFIG.y.min_ft) / total_ft;

    /** canvas y is inverted relative to slider */
    return this.PLATE_CONFIG.canvas.height_px * (1 - y_perc);
  }

  private ftToPxX(x_ft: number) {
    /** convert x_ft into a px value that works for the canvas by translating so that x.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(
      this.PLATE_CONFIG.x.max_ft - this.PLATE_CONFIG.x.min_ft
    );
    const x_perc = (x_ft - this.PLATE_CONFIG.x.min_ft) / total_ft;
    return this.PLATE_CONFIG.canvas.width_px * x_perc;
  }

  drawRulers(ctx: CanvasRenderingContext2D, color = LINE_COLOR) {
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    /** draw x ruler */
    const x_ruler_pos_y = Math.min(
      this.CONSTANTS_PX.origin_y,
      this.ftToPxY(this.PLATE_CONFIG.y.min_ft)
    );
    ctx.moveTo(0, x_ruler_pos_y);
    ctx.lineTo(this.PLATE_CONFIG.canvas.width_px, x_ruler_pos_y);
    ctx.stroke();

    /** draw x ruler tick marks per foot */
    ArrayHelper.getIntegerOptions(
      Math.ceil(this.PLATE_CONFIG.x.min_ft),
      Math.floor(this.PLATE_CONFIG.x.max_ft)
    ).forEach((o) => {
      const intValue = parseInt(o.value);
      const x = this.ftToPxX(intValue);
      ctx.moveTo(x, x_ruler_pos_y);
      ctx.lineTo(x, x_ruler_pos_y - 5);
      ctx.stroke();

      ctx.fillText(`${intValue}'`, x - 3, x_ruler_pos_y - 10);
    });

    /** draw y ruler along the side */
    ctx.moveTo(0, 1);
    ctx.lineTo(0, this.CONSTANTS_PX.origin_y);
    ctx.stroke();

    /** draw x ruler tick marks per foot */
    ArrayHelper.getIntegerOptions(
      0,
      Math.floor(this.PLATE_CONFIG.y.max_ft)
    ).forEach((o) => {
      const intValue = parseInt(o.value);
      const y = this.ftToPxY(intValue);
      ctx.moveTo(0, y);
      ctx.lineTo(5, y);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(`${intValue}'`, 10, y + 3);
      }
    });
  }

  drawStrikeZone(ctx: CanvasRenderingContext2D, color = LINE_COLOR) {
    this.drawStrikeZoneGrid(ctx);

    /** draw strike zone external box */
    ctx.strokeStyle = color;
    ctx.strokeRect(
      this.SZ_PX.left,
      this.SZ_PX.top,
      this.SZ_PX.right - this.SZ_PX.left,
      this.SZ_PX.bottom - this.SZ_PX.top
    );
  }

  drawStrikeZoneSafetyRegion(ctx: CanvasRenderingContext2D, hitter: IHitter) {
    const REGION_BOTTOM_FT = -3;
    const REGION_HEIGHT_FT = 8;
    const REGION_WIDTH_FT = 4;
    const USE_GRADIENT = true;

    if (!hitter) {
      return;
    }

    if (!hitter.side) {
      return;
    }

    const xInsideFt = EllipseHelper.getSafeHitterInnerX(hitter.safety_buffer);
    if (xInsideFt === undefined) {
      return;
    }

    const isLeft = hitter.side === HitterSide.LHH;

    const leftX = this.ftToPxX(
      isLeft ? xInsideFt : -xInsideFt - REGION_WIDTH_FT
    );
    const rightX = this.ftToPxX(
      isLeft ? xInsideFt + REGION_WIDTH_FT : -xInsideFt
    );

    if (USE_GRADIENT) {
      // gradient
      const grd = ctx.createLinearGradient(
        isLeft ? leftX : rightX,
        0,
        isLeft ? rightX : leftX,
        0
      );

      // yellow
      grd.addColorStop(0, 'rgba(255, 193, 7, 0)');

      // red
      grd.addColorStop(0.5, 'rgba(245, 89, 89, 0.5)');

      ctx.fillStyle = grd;
    } else {
      // flat
      ctx.fillStyle = 'rgba(255, 193, 7, 0.2)';
    }

    ctx.fillRect(
      leftX,
      this.ftToPxY(REGION_BOTTOM_FT + REGION_HEIGHT_FT),
      rightX - leftX,
      this.ftToPxY(REGION_BOTTOM_FT) -
        this.ftToPxY(REGION_BOTTOM_FT + REGION_HEIGHT_FT)
    );
  }

  /** the area in which a pitch could land and the training dialog will auto-adjust the pitch to the center without user intervention */
  drawStrikeZoneAutoAdjustArea(
    ctx: CanvasRenderingContext2D,
    // green box around strikezone
    color = '#20573E'
  ) {
    ctx.strokeStyle = color;
    ctx.strokeRect(
      this.SZ_AUTO_ADJUST_PX.left,
      this.SZ_AUTO_ADJUST_PX.top,
      this.SZ_AUTO_ADJUST_PX.right - this.SZ_AUTO_ADJUST_PX.left,
      this.SZ_AUTO_ADJUST_PX.bottom - this.SZ_AUTO_ADJUST_PX.top
    );
  }

  drawDetailedStrikeZone(ctx: CanvasRenderingContext2D, color = '#FF0000') {
    this.drawStrikeZoneGrid(ctx, color);

    /** draw strike zone external box */
    ctx.strokeStyle = color;
    ctx.strokeRect(
      this.SZ_PX.left,
      this.SZ_PX.top,
      this.SZ_PX.right - this.SZ_PX.left,
      this.SZ_PX.bottom - this.SZ_PX.top
    );

    /** gutter (bigger) box */
    ctx.strokeRect(
      this.SZ_GUTTER_PX.left,
      this.SZ_GUTTER_PX.top,
      this.SZ_GUTTER_PX.right - this.SZ_GUTTER_PX.left,
      this.SZ_GUTTER_PX.bottom - this.SZ_GUTTER_PX.top
    );

    /** top line separating corners */
    ctx.moveTo(this.SZ_PX.middle_x, this.SZ_GUTTER_PX.top);
    ctx.lineTo(this.SZ_PX.middle_x, this.SZ_PX.top);

    /** bottom line separating corners */
    ctx.moveTo(this.SZ_PX.middle_x, this.SZ_GUTTER_PX.bottom);
    ctx.lineTo(this.SZ_PX.middle_x, this.SZ_PX.bottom);

    /** left line separating corners */
    ctx.moveTo(this.SZ_GUTTER_PX.left, this.SZ_PX.middle_y);
    ctx.lineTo(this.SZ_PX.left, this.SZ_PX.middle_y);

    /** right line separating corners */
    ctx.moveTo(this.SZ_GUTTER_PX.right, this.SZ_PX.middle_y);
    ctx.lineTo(this.SZ_PX.right, this.SZ_PX.middle_y);

    ctx.stroke();
  }

  private drawStrikeZoneGrid(ctx: CanvasRenderingContext2D, color = '#666666') {
    ctx.strokeStyle = color;

    /** horizontal 1 */
    ctx.moveTo(
      this.SZ_PX.left,
      this.SZ_PX.top + this.strikeZoneGridHeight('px')
    );
    ctx.lineTo(
      this.SZ_PX.right,
      this.SZ_PX.top + this.strikeZoneGridHeight('px')
    );

    /** horizontal 2 */
    ctx.moveTo(
      this.SZ_PX.left,
      this.SZ_PX.top + this.strikeZoneGridHeight('px') * 2
    );
    ctx.lineTo(
      this.SZ_PX.right,
      this.SZ_PX.top + this.strikeZoneGridHeight('px') * 2
    );

    /** vertical 1 */
    ctx.moveTo(
      this.SZ_PX.left + this.strikeZoneGridWidth('px'),
      this.SZ_PX.top
    );
    ctx.lineTo(
      this.SZ_PX.left + this.strikeZoneGridWidth('px'),
      this.SZ_PX.bottom
    );

    /** vertical 2 */
    ctx.moveTo(
      this.SZ_PX.left + this.strikeZoneGridWidth('px') * 2,
      this.SZ_PX.top
    );
    ctx.lineTo(
      this.SZ_PX.left + this.strikeZoneGridWidth('px') * 2,
      this.SZ_PX.bottom
    );

    /** stroke the lines above all at once */
    ctx.stroke();
  }

  fillStrikeZoneCenterGrid(ctx: CanvasRenderingContext2D, color = '#198754') {
    ctx.fillStyle = color;

    /** horizontal 1 */
    ctx.fillRect(
      this.SZ_PX.left + this.strikeZoneGridWidth('px'),
      this.SZ_PX.top + this.strikeZoneGridHeight('px'),
      this.strikeZoneGridWidth('px'),
      this.strikeZoneGridHeight('px')
    );
  }

  private drawHomePlate(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = HOME_PLATE_COLOR;
    const polygon = new Path2D();

    // top left corner
    polygon.moveTo(this.SZ_PX.left, this.CONSTANTS_PX.origin_y);
    polygon.lineTo(this.SZ_PX.left - 5, this.CONSTANTS_PX.origin_y + 10);

    // peak in middlez
    polygon.lineTo(this.CONSTANTS_PX.origin_x, this.CONSTANTS_PX.origin_y + 20);
    polygon.lineTo(this.SZ_PX.right + 5, this.CONSTANTS_PX.origin_y + 10);

    // top right corner
    polygon.lineTo(this.SZ_PX.right, this.CONSTANTS_PX.origin_y);
    polygon.closePath();

    ctx.fill(polygon);
  }

  /**
   *
   * @param ctx
   * @param inverted -1 for box left of plate, 1 for box right of plate (pitcher's pov)
   */
  private drawBattersBox(ctx: CanvasRenderingContext2D, inverted: -1 | 1) {
    const INSIDE_X =
      inverted === -1
        ? this.HOME_PLATE_PX.left_line_inside
        : this.HOME_PLATE_PX.right_line_inside;

    const TOP_Y = this.CONSTANTS_PX.origin_y - 10;
    const BOT_Y = this.PLATE_CONFIG.canvas.height_px - 50;

    // outer box
    ctx.fillStyle = BATTERS_BOX_COLOR;
    const CORNERS_OUT = {
      TOP_IN: { x: INSIDE_X, y: TOP_Y },
      TOP_OUT: { x: INSIDE_X + 80 * inverted, y: TOP_Y },
      BOT_IN: { x: INSIDE_X + 50 * inverted, y: BOT_Y },
      BOT_OUT: { x: INSIDE_X + 176 * inverted, y: BOT_Y },
    };

    const outerPolygon = new Path2D();

    outerPolygon.moveTo(CORNERS_OUT.TOP_IN.x, CORNERS_OUT.TOP_IN.y);
    outerPolygon.lineTo(CORNERS_OUT.BOT_IN.x, CORNERS_OUT.BOT_IN.y);
    outerPolygon.lineTo(CORNERS_OUT.BOT_OUT.x, CORNERS_OUT.BOT_OUT.y);
    outerPolygon.lineTo(CORNERS_OUT.TOP_OUT.x, CORNERS_OUT.TOP_OUT.y);

    outerPolygon.closePath();

    ctx.fill(outerPolygon);

    // inner box - subtracted from outer box
    ctx.globalCompositeOperation = 'destination-out';
    const CORNERS_IN = {
      TOP_IN: { x: INSIDE_X + 14 * inverted, y: TOP_Y + 5 },
      TOP_OUT: { x: INSIDE_X + 77 * inverted, y: TOP_Y + 5 },
      BOT_IN: { x: INSIDE_X + 55 * inverted, y: BOT_Y - 8 },
      BOT_OUT: { x: INSIDE_X + 148 * inverted, y: BOT_Y - 8 },
    };

    const innerPolygon = new Path2D();

    innerPolygon.moveTo(CORNERS_IN.TOP_IN.x, CORNERS_IN.TOP_IN.y);
    innerPolygon.lineTo(CORNERS_IN.BOT_IN.x, CORNERS_IN.BOT_IN.y);
    innerPolygon.lineTo(CORNERS_IN.BOT_OUT.x, CORNERS_IN.BOT_OUT.y);
    innerPolygon.lineTo(CORNERS_IN.TOP_OUT.x, CORNERS_IN.TOP_OUT.y);

    innerPolygon.closePath();
    ctx.fill(innerPolygon);

    // return to default setting
    ctx.globalCompositeOperation = 'source-over';
  }

  /** home plate + batter's boxes */
  drawGround(ctx: CanvasRenderingContext2D) {
    if (this.PLATE_CONFIG.y.min_ft > 0) {
      return;
    }
    this.drawHomePlate(ctx);
    this.drawBattersBox(ctx, 1);
    this.drawBattersBox(ctx, -1);
  }

  drawDot(
    ctx: CanvasRenderingContext2D,
    loc: IPlateLoc,
    config: {
      color: string;
      size: number;
      label?: string;
    }
  ) {
    if (
      loc.plate_x < this.PLATE_CONFIG.x.min_ft ||
      loc.plate_x > this.PLATE_CONFIG.x.max_ft
    ) {
      return;
    }

    if (
      loc.plate_z < this.PLATE_CONFIG.y.min_ft ||
      loc.plate_z > this.PLATE_CONFIG.y.max_ft
    ) {
      return;
    }

    ctx.strokeStyle = INVISIBLE_COLOR;
    ctx.fillStyle = config.color;
    ctx.beginPath();
    ctx.ellipse(
      this.ftToPxX(loc.plate_x),
      this.ftToPxY(loc.plate_z),
      config.size,
      config.size,
      0,
      0,
      RAD_FULL_ROTATION
    );
    ctx.fill();
    ctx.stroke();

    if (config.label) {
      ctx.font = LABEL_FONT;
      ctx.fillText(
        config.label,
        this.ftToPxX(loc.plate_x) + 15,
        this.ftToPxY(loc.plate_z) + config.size / 2
      );
    }
  }

  drawTarget(
    ctx: CanvasRenderingContext2D,
    loc: {
      xFt: number;
      zFt: number;
    }
  ) {
    const size = 32;

    const crosshairs = new Image(size, size);

    crosshairs.onload = () => {
      const x = this.ftToPxX(loc.xFt);
      const y = this.ftToPxY(loc.zFt);
      const half = size / 2;

      // rescale the image to match target size
      ctx.drawImage(crosshairs, x - half, y - half, size, size);
    };

    crosshairs.src = '/img/crosshair.svg';
  }

  drawCheckmark(ctx: CanvasRenderingContext2D) {
    const size = 32;

    const checkmark = new Image(size, size);

    checkmark.onload = () => {
      const x = this.ftToPxX(DEFAULT_PLATE.plate_x);
      const y = this.ftToPxY(DEFAULT_PLATE.plate_z);
      const half = size / 2;

      // rescale the image to match target size
      ctx.drawImage(checkmark, x - half, y - half, size, size);
    };

    checkmark.src = '/img/check-circled-filled.svg';
  }

  drawTrainingDot(
    ctx: CanvasRenderingContext2D,
    loc: IPlateLoc,
    config: {
      isNew: boolean;
      size: number;
      index: number;
      label?: string;
    }
  ) {
    if (
      loc.plate_x < this.PLATE_CONFIG.x.min_ft ||
      loc.plate_x > this.PLATE_CONFIG.x.max_ft
    ) {
      return;
    }

    if (
      loc.plate_z < this.PLATE_CONFIG.y.min_ft ||
      loc.plate_z > this.PLATE_CONFIG.y.max_ft
    ) {
      return;
    }

    const colors = config.isNew ? NEW_TRAINING_DOT : OLD_TRAINING_DOT;

    ctx.fillStyle = colors.backgroundColor;
    ctx.strokeStyle = colors.borderColor;

    ctx.beginPath();
    ctx.ellipse(
      this.ftToPxX(loc.plate_x),
      this.ftToPxY(loc.plate_z),
      config.size,
      config.size,
      0,
      0,
      RAD_FULL_ROTATION
    );
    ctx.fill();
    ctx.stroke();

    ctx.font = LABEL_FONT;
    ctx.fillStyle = colors.labelColor;

    ctx.fillText(
      // inside the circle
      config.index.toString(),
      this.ftToPxX(loc.plate_x) - config.size / 2 + 1,
      this.ftToPxY(loc.plate_z) + config.size / 2
    );

    if (config.label) {
      // off to the side
      ctx.fillText(
        config.label,
        this.ftToPxX(loc.plate_x) + 15,
        this.ftToPxY(loc.plate_z) + config.size / 2
      );
    }
  }

  drawEllipse(
    ctx: CanvasRenderingContext2D,
    loc: IPlateLoc,
    config: {
      fillStyle: string;
      ellipse: IEllipse;
      scaling_factor: number;
      angle_radians: number;
      bounds?: boolean;
      // like '#FF0000'
      strokeStyle?: string;
      stroke?: boolean;
    }
  ) {
    ctx.strokeStyle = config.stroke
      ? config.strokeStyle ?? '#FF0000'
      : INVISIBLE_COLOR;

    ctx.fillStyle = config.fillStyle;

    ctx.beginPath();
    ctx.ellipse(
      /** x position */
      this.ftToPxX(loc.plate_x),

      /** y position */
      this.ftToPxY(loc.plate_z),

      /** radiusX */
      (this.PLATE_CONFIG.canvas.width_px / 2 / this.PLATE_CONFIG.x.max_ft) *
        Math.sqrt(config.ellipse.majorRadius * config.scaling_factor),

      /** radiusY */
      (this.PLATE_CONFIG.canvas.height_px /
        (this.PLATE_CONFIG.y.max_ft - this.PLATE_CONFIG.y.min_ft)) *
        Math.sqrt(config.ellipse.minorRadius * config.scaling_factor),

      /** rotation */
      config.angle_radians,

      /** start angle */
      0,

      /** end angle */
      RAD_FULL_ROTATION
    );
    ctx.fill();
    ctx.stroke();

    if (config.bounds) {
      const limits = EllipseHelper.getEllipseLimits(
        config.ellipse,
        config.scaling_factor
      );

      const ellipse_bound_px = {
        top: this.ftToPxY(loc.plate_z + limits.absMaxY),
        bottom: this.ftToPxY(loc.plate_z - limits.absMaxY),
        left: this.ftToPxX(loc.plate_x - limits.absMaxX),
        right: this.ftToPxX(loc.plate_x + limits.absMaxX),
      };

      /** draw bounding box */
      ctx.strokeStyle = '#FFFFFF';
      ctx.strokeRect(
        ellipse_bound_px.left,
        ellipse_bound_px.top,
        ellipse_bound_px.right - ellipse_bound_px.left,
        ellipse_bound_px.bottom - ellipse_bound_px.top
      );
    }
  }

  // this belongs on plate canvas (instead of TrainingHelper) b/c of this.PLATE_CONFIG being potentially dynamic
  nextTrainStep(config: {
    mode: TrainingMode;
    prevStep: TrainStep;
    shot: IMachineShot | undefined;
    allShots: IMachineShot[];
    threshold: number;
    requireConfidence: boolean;
  }): TrainStep {
    const FN_NAME = 'nextTrainStep';

    if (config.prevStep === TrainStep.Sending) {
      vDebug(`${FN_NAME}: was ${TrainStep.Sending} > ${TrainStep.Firing}`);
      return TrainStep.Firing;
    }

    if (!config.shot) {
      // e.g. changed pitch
      vDebug(`${FN_NAME}: no shot > ${TrainStep.Sending}`);
      return TrainStep.Sending;
    }

    const loc = TrajHelper.getPlateLoc(
      config.shot.user_traj ?? config.shot.traj
    );

    const inZone = TrainingHelper.isPlateInZone({
      loc: loc,
      zone: this.PLATE_CONFIG.strikeZone,
      buffer: SZ_GREEN_BUFFER_FT,
    });

    switch (config.mode) {
      case TrainingMode.Manual: {
        const incompleteResult = TrainStep.Sending;

        if (!inZone) {
          vDebug(`${FN_NAME}:  manual outside zone > ${incompleteResult}`);
          return incompleteResult;
        }

        if (config.allShots.length < config.threshold) {
          vDebug(
            `${FN_NAME}:  manual shots (${config.allShots.length}) less than threshold (${config.threshold}) > ${incompleteResult}`
          );
          return incompleteResult;
        }

        vDebug(`${FN_NAME}: manual > ${TrainStep.Complete}`);
        return TrainStep.Complete;
      }

      case TrainingMode.Quick: {
        const incompleteResult = ALLOW_ADJUSTMENT
          ? TrainStep.ManualAdjust
          : TrainStep.Sending;

        if (!inZone) {
          vDebug(`${FN_NAME}: quick outside zone > ${incompleteResult}`);
          return incompleteResult;
        }

        if (!config.requireConfidence) {
          const totalShots = config.allShots.length;

          if (totalShots < config.threshold) {
            vDebug(
              `${FN_NAME}: quick shots ignoring confidence (${totalShots}) less than threshold (${config.threshold}) > ${TrainStep.Sending}`
            );
            return TrainStep.Sending;
          }

          vDebug(`${FN_NAME}: quick > ${TrainStep.Complete}`);
          return TrainStep.Complete;
        }

        const withConfidence =
          config.shot.confidence &&
          TrainingHelper.isConfidenceSufficient({
            confidence: config.shot.confidence,
            minValue: MIN_CONFIDENCE,
          });

        if (!withConfidence) {
          vDebug(`${FN_NAME}: quick without confidence > ${incompleteResult}`);
          return incompleteResult;
        }

        const totalShots = config.allShots.filter(
          (s) =>
            s.confidence &&
            TrainingHelper.isConfidenceSufficient({
              confidence: s.confidence,
              minValue: MIN_CONFIDENCE,
            })
        ).length;

        if (totalShots < config.threshold) {
          vDebug(
            `${FN_NAME}: quick shots with confidence (${totalShots}) less than threshold (${config.threshold}) > ${TrainStep.Sending}`
          );
          return TrainStep.Sending;
        }

        vDebug(`${FN_NAME}: quick > ${TrainStep.Complete}`);
        return TrainStep.Complete;
      }

      default: {
        return TrainStep.Firing;
      }
    }
  }

  getStDev(nums: number[], baseline_stdev: number): number {
    if (nums.length < 2) {
      return baseline_stdev;
    }
    const alpha = 20 / (19 + Math.pow(nums.length, 2));
    const mean = nums.reduce((a, b) => a + b) / nums.length;
    const meas_stdev = Math.sqrt(
      nums.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
        (nums.length - 1)
    );
    return alpha * baseline_stdev + (1 - alpha) * meas_stdev;
  }

  normalCDF(x: number): number {
    const t = 1 / (1 + 0.2315419 * Math.abs(x));
    const d = 0.3989423 * Math.exp((-x * x) / 2);
    const prob =
      d *
      t *
      (0.3193815 +
        t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
    if (x > 0) {
      return 1 - prob;
    }
    return prob;
  }

  private getProbMeanInRange(
    samples: number[],
    baseline_stdev: number,
    target: number,
    threshold: number
  ): number {
    const sample_mean = samples.reduce((a, b) => a + b) / samples.length;
    const sem =
      this.getStDev(samples, baseline_stdev) / Math.sqrt(samples.length);
    const upper = target + threshold;
    const lower = target - threshold;
    return (
      this.normalCDF((upper - sample_mean) / sem) -
      this.normalCDF((lower - sample_mean) / sem)
    );
  }

  // this belongs on plate canvas (instead of TrainingHelper) b/c of this.PLATE_CONFIG being potentially dynamic
  preOptimizeStep(config: IPreOptimize): PTStep {
    const FN_NAME = 'preOptimizeStep';

    if (!config.pitch.bs) {
      throw new Error(`${FN_NAME}: cannot be applied to a pitch without bs`);
    }

    if (!config.pitch.traj) {
      throw new Error(`${FN_NAME}: cannot be applied to a pitch without traj`);
    }

    if (config.prevStep === PTStep.Sending) {
      vDebug(`${FN_NAME}: was ${PTStep.Sending} > ${PTStep.Firing}`);
      return PTStep.Firing;
    }

    if (!config.shot) {
      // e.g. changed pitch
      vDebug(`${FN_NAME}: no shot > ${PTStep.Sending}`);
      return PTStep.Sending;
    }

    if (config.spec.useProbability) {
      if (
        config.pitch.priority === BuildPriority.Breaks &&
        config.pitch.breaks
      ) {
        return this.preOptimizeProbabilityBreaks(config);
      }

      return this.preOptimizeProbabilitySpins(config);
    }

    console.debug('PlateCanvas: using regular method to seek next step...');

    if (config.allShots.length < config.spec.sampleSize) {
      vDebug(`${FN_NAME}: gather shots for optimization > ${PTStep.Sending}`);
      return PTStep.Sending;
    }

    if (config.pitch.priority === BuildPriority.Breaks && config.pitch.breaks) {
      const breaks = config.allShots
        .map((s) => s.break)
        .filter((b) => !!b) as IRapsodoBreak[];

      if (breaks.length < config.spec.sampleSize) {
        vDebug(`${FN_NAME}: insufficient breaks > ${PTStep.Sending}`);
        return PTStep.Sending;
      }

      const summary_break = config.summaryFn(breaks) as IRapsodoBreak;

      const deltaX = Math.abs(
        -1 * summary_break.PITCH_HBTrajectory * METERS_TO_FT * FT_TO_INCHES -
          config.pitch.breaks.xInches
      );

      if (deltaX > config.spec.deltaBreaksInches) {
        vDebug(
          `${FN_NAME}: large breaks X delta (${deltaX} > ${config.spec.deltaBreaksInches}) > ${PTStep.SeekBreaks}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekBreaks
          : PTStep.SeekFailure;
      }

      const deltaZ = Math.abs(
        summary_break.PITCH_VBTrajectory * METERS_TO_FT * FT_TO_INCHES -
          config.pitch.breaks.zInches
      );

      if (deltaZ > config.spec.deltaBreaksInches) {
        vDebug(
          `${FN_NAME}: large breaks Z delta (${deltaZ} > ${config.spec.deltaBreaksInches}) > ${PTStep.SeekBreaks}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekBreaks
          : PTStep.SeekFailure;
      }

      const trajs = config.allShots
        .map((s) => s.traj)
        .filter((t) => !!t) as ITrajectory[];

      if (trajs.length < config.spec.sampleSize) {
        vDebug(`${FN_NAME}: insufficient trajectories > ${PTStep.Sending}`);
        return PTStep.Sending;
      }

      const summary_traj = config.summaryFn(trajs) as ITrajectory;

      const targetSpeed = TrajHelper.getSpeedMPH(config.pitch.traj);
      const summarySpeed = TrajHelper.getSpeedMPH(summary_traj);

      const deltaSpeed =
        targetSpeed === undefined || summarySpeed === undefined
          ? undefined
          : Math.abs(targetSpeed - summarySpeed);

      if (deltaSpeed === undefined) {
        vDebug(
          `${FN_NAME}: invalid speed delta (${deltaSpeed} > ${config.spec.deltaSpeedMPH}) > ${PTStep.SeekFailure}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpeed
          : PTStep.SeekFailure;
      }

      if (deltaSpeed > config.spec.deltaSpeedMPH) {
        vDebug(
          `${FN_NAME}: large speed delta (${deltaSpeed} > ${config.spec.deltaSpeedMPH}) > ${PTStep.SeekSpeed}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpeed
          : PTStep.SeekFailure;
      }
    }

    if (config.pitch.priority === BuildPriority.Spins && config.pitch.bs) {
      const bss = config.allShots
        .map((s) => s.bs)
        .filter((b) => !!b) as IBallState[];

      if (bss.length < config.spec.sampleSize) {
        vDebug(`${FN_NAME}: insufficient ball states > ${PTStep.Sending}`);
        return PTStep.Sending;
      }

      const summary_bs = config.summaryFn(bss) as IBallState;
      const deltaWx = Math.abs(summary_bs.wx - config.pitch.bs.wx);
      if (deltaWx > config.spec.deltaSpinsRPM) {
        vDebug(
          `${FN_NAME}: large wx delta (${deltaWx} > ${config.spec.deltaSpinsRPM}) > ${PTStep.SeekSpins}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpins
          : PTStep.SeekFailure;
      }

      const deltaWy = Math.abs(summary_bs.wy - config.pitch.bs.wy);
      if (deltaWy > config.spec.deltaSpinsRPM) {
        vDebug(
          `${FN_NAME}: large wy delta (${deltaWy} > ${config.spec.deltaSpinsRPM}) > ${PTStep.SeekSpins}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpins
          : PTStep.SeekFailure;
      }

      const deltaWz = Math.abs(summary_bs.wz - config.pitch.bs.wz);
      if (deltaWz > config.spec.deltaSpinsRPM) {
        vDebug(
          `${FN_NAME}: large wz delta (${deltaWz} > ${config.spec.deltaSpinsRPM}) > ${PTStep.SeekSpins}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpins
          : PTStep.SeekFailure;
      }

      const deltaSpeed = Math.abs(
        BallHelper.getSpeed(summary_bs) - BallHelper.getSpeed(config.pitch.bs)
      );

      if (deltaSpeed > config.spec.deltaSpeedMPH) {
        vDebug(
          `${FN_NAME}: large speed delta (${deltaSpeed} > ${config.spec.deltaSpeedMPH}) > ${PTStep.SeekSpeed}`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpeed
          : PTStep.SeekFailure;
      }
    }

    // safe to leave seeking phase
    vDebug(`${FN_NAME}: acceptable ms > ${PTStep.SeekSuccess}`);
    return PTStep.SeekSuccess;
  }

  private getProbSpeed(config: {
    bss: IBallState[];
    bs: IBallState;
    deltaSpeedMPH: number;
  }) {
    const output = this.getProbMeanInRange(
      config.bss.map((bs) => BallHelper.getSpeed(bs)),
      BASE_SPEED_SD_MPH,
      BallHelper.getSpeed(config.bs),
      config.deltaSpeedMPH
    );

    return output;
  }

  private preOptimizeProbabilityBreaks(
    config: IPreOptimize
  ):
    | PTStep.SeekFailure
    | PTStep.SeekBreaks
    | PTStep.SeekSuccess
    | PTStep.Sending {
    const FN_NAME = 'preOptimizeProbabilityBreaks';

    if (!config.pitch.bs) {
      return PTStep.SeekFailure;
    }

    if (!config.pitch.traj) {
      return PTStep.SeekFailure;
    }

    const targetSpeed = TrajHelper.getSpeedMPH(config.pitch.traj);

    if (targetSpeed === undefined) {
      return PTStep.SeekFailure;
    }

    if (!config.pitch.breaks) {
      return PTStep.SeekFailure;
    }

    const trajs = config.allShots
      .map((s) => s.traj)
      .filter((t) => !!t) as ITrajectory[];

    const breaks = config.allShots
      .map((s) => s.break)
      .filter((b) => !!b) as IRapsodoBreak[];

    const pSpeed = this.getProbMeanInRange(
      trajs
        .map((t) => TrajHelper.getSpeedMPH(t))
        .filter((t) => t !== undefined) as number[],
      BASE_SPEED_SD_MPH,
      targetSpeed,
      config.spec.deltaSpeedMPH
    );

    const pBreakX = this.getProbMeanInRange(
      breaks.map((b) => -1 * b.PITCH_HBTrajectory * METERS_TO_INCHES),
      BASE_BREAKS_SD_IN,
      config.pitch.breaks.xInches,
      config.spec.deltaBreaksInches
    );

    const pBreakZ = this.getProbMeanInRange(
      breaks.map((b) => b.PITCH_VBTrajectory * METERS_TO_INCHES),
      BASE_BREAKS_SD_IN,
      config.pitch.breaks.zInches,
      config.spec.deltaBreaksInches
    );

    const pAcceptable = pSpeed * pBreakX * pBreakZ;

    if (pAcceptable < 0) {
      return PTStep.SeekFailure;
    }

    if (pAcceptable > HIGH_PROBABILITY) {
      vDebug(
        `${FN_NAME}: high probability of success (${pAcceptable} > ${HIGH_PROBABILITY}) > ${PTStep.SeekSuccess}`
      );
      return PTStep.SeekSuccess;
    }

    if (pAcceptable > LOW_PROBABILITY) {
      if (config.allShots.length >= config.spec.sampleSize) {
        vDebug(
          `${FN_NAME}: reached max number of samples > ${PTStep.SeekBreaks}
              }`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekBreaks
          : PTStep.SeekFailure;
      }

      vDebug(
        `${FN_NAME}: uncertain probability (${LOW_PROBABILITY} < ${pAcceptable} < ${HIGH_PROBABILITY}) and not reached max number of samples (${config.allShots.length} < ${config.spec.sampleSize}) > ${PTStep.Sending}`
      );
      return PTStep.Sending;
    }

    vDebug(
      `${FN_NAME}: low probability of success (${pAcceptable} < ${LOW_PROBABILITY}) > ${PTStep.SeekBreaks}`
    );
    return config.currentIteration < config.spec.iterations
      ? PTStep.SeekBreaks
      : PTStep.SeekFailure;
  }

  private preOptimizeProbabilitySpins(
    config: IPreOptimize
  ):
    | PTStep.SeekFailure
    | PTStep.SeekSpins
    | PTStep.SeekSuccess
    | PTStep.Sending {
    const FN_NAME = 'preOptimizeProbabilitySpins';

    if (!config.pitch.bs) {
      return PTStep.SeekFailure;
    }

    const bss = config.allShots
      .map((s) => s.bs)
      .filter((b) => !!b) as IBallState[];

    const pSpeed = this.getProbSpeed({
      bss: bss,
      bs: config.pitch.bs,
      deltaSpeedMPH: config.spec.deltaSpeedMPH,
    });

    const pSpinX = this.getProbMeanInRange(
      bss.map((bs) => bs.wx),
      BASE_SPIN_SD_RPM,
      config.pitch.bs.wx,
      config.spec.deltaSpinsRPM
    );

    const pSpinY = this.getProbMeanInRange(
      bss.map((bs) => bs.wy),
      BASE_SPIN_SD_RPM,
      config.pitch.bs.wy,
      config.spec.deltaSpinsRPM
    );

    const pSpinZ = this.getProbMeanInRange(
      bss.map((bs) => bs.wz),
      BASE_SPIN_SD_RPM,
      config.pitch.bs.wz,
      config.spec.deltaSpinsRPM
    );

    const pAcceptable = pSpeed * pSpinX * pSpinY * pSpinZ;

    if (pAcceptable < 0) {
      return PTStep.SeekFailure;
    }

    if (pAcceptable > HIGH_PROBABILITY) {
      vDebug(
        `${FN_NAME}: high probability of success (${pAcceptable} > ${HIGH_PROBABILITY}) > ${PTStep.SeekSuccess}`
      );
      return PTStep.SeekSuccess;
    }

    if (pAcceptable > LOW_PROBABILITY) {
      if (config.allShots.length >= config.spec.sampleSize) {
        vDebug(
          `${FN_NAME}: reached max number of samples > ${PTStep.SeekSpins}
              }`
        );
        return config.currentIteration < config.spec.iterations
          ? PTStep.SeekSpins
          : PTStep.SeekFailure;
      }

      vDebug(
        `${FN_NAME}: uncertain probability (${LOW_PROBABILITY} < ${pAcceptable} < ${HIGH_PROBABILITY}) and not reached max number of samples (${config.allShots.length} < ${config.spec.sampleSize}) > ${PTStep.Sending}`
      );
      return PTStep.Sending;
    }

    vDebug(
      `${FN_NAME}: low probability of success (${pAcceptable} < ${LOW_PROBABILITY}) > ${PTStep.SeekSpins}`
    );
    return config.currentIteration < config.spec.iterations
      ? PTStep.SeekSpins
      : PTStep.SeekFailure;
  }

  // this belongs on plate canvas (instead of TrainingHelper) b/c of this.PLATE_CONFIG being potentially dynamic
  postOptimizeStep(config: IPostOptimize): PTStep {
    const FN_NAME = 'nextStepAfterOptimize';

    if (!config.pitch.bs) {
      throw new Error(`${FN_NAME}: cannot be applied to a pitch without bs`);
    }

    if (config.prevStep === PTStep.Sending) {
      vDebug(`${FN_NAME}: was ${PTStep.Sending} > ${PTStep.Firing}`);
      return PTStep.Firing;
    }

    if (!config.shot) {
      // e.g. changed pitch
      vDebug(`${FN_NAME}: no shot > ${PTStep.Sending}`);
      return PTStep.Sending;
    }

    // collect shots as usual
    const loc = TrajHelper.getPlateLoc(
      config.shot.user_traj ?? config.shot.traj
    );

    const inZone = TrainingHelper.isPlateInZone({
      loc: loc,
      zone: this.PLATE_CONFIG.strikeZone,
      buffer: SZ_GREEN_BUFFER_FT,
    });

    const incompleteResult = PTStep.Sending;

    if (!inZone) {
      vDebug(`${FN_NAME}: outside zone > ${incompleteResult}`);
      return incompleteResult;
    }

    if (!config.requireConfidence) {
      const totalShots = config.allShots.length;

      if (totalShots < config.threshold) {
        vDebug(
          `${FN_NAME}: ignoring confidence (${totalShots}) less than threshold (${config.threshold}) > ${PTStep.Sending}`
        );
        return PTStep.Sending;
      }

      vDebug(`${FN_NAME}: ${config.prevStep} > ${PTStep.Complete}`);
      return PTStep.Complete;
    }

    const withConfidence =
      config.shot.confidence &&
      TrainingHelper.isConfidenceSufficient({
        confidence: config.shot.confidence,
        minValue: MIN_CONFIDENCE,
      });

    if (!withConfidence) {
      vDebug(`${FN_NAME}: direct without confidence > ${incompleteResult}`);
      return incompleteResult;
    }

    const totalShots = config.allShots.filter(
      (s) =>
        s.confidence &&
        TrainingHelper.isConfidenceSufficient({
          confidence: s.confidence,
          minValue: MIN_CONFIDENCE,
        })
    ).length;

    if (totalShots < config.threshold) {
      vDebug(
        `${FN_NAME}: shots with confidence (${totalShots}) less than threshold (${config.threshold}) > ${PTStep.Sending}`
      );
      return PTStep.Sending;
    }

    vDebug(`${FN_NAME}: direct > ${PTStep.Complete}`);
    return PTStep.Complete;
  }

  fillStrikeZoneOutcome(
    ctx: CanvasRenderingContext2D,
    loc: IPlateLoc,
    color = '#FF0000'
  ) {
    const summary = PlateHelper.getSummary({
      hitterPresent: false,
      strikeZone: this.PLATE_CONFIG.strikeZone,
      height_ft: loc.plate_z,
      side_ft: loc.plate_x,
    });

    if (summary.outcome === PlateOutcome.Ball) {
      this.fillStrikeZoneCorner(ctx, summary, color);
      return;
    }

    if (summary.outcome === PlateOutcome.Strike) {
      this.fillStrikeZoneGrid(ctx, summary, color);
      return;
    }
  }

  private fillStrikeZoneCorner(
    ctx: CanvasRenderingContext2D,
    summary: IPlateSummary,
    color = '#FF0000'
  ) {
    ctx.fillStyle = color;

    if (summary.horizontal === PlateH.L && summary.vertical === PlateV.T) {
      ctx.beginPath();
      ctx.moveTo(
        this.SZ_GUTTER_PX.left + INDENT_PX,
        this.SZ_GUTTER_PX.top + INDENT_PX
      );
      ctx.lineTo(
        this.SZ_PX.middle_x - INDENT_PX,
        this.SZ_GUTTER_PX.top + INDENT_PX
      );
      ctx.lineTo(this.SZ_PX.middle_x - INDENT_PX, this.SZ_PX.top - INDENT_PX);
      ctx.lineTo(this.SZ_PX.left - INDENT_PX, this.SZ_PX.top - INDENT_PX);
      ctx.lineTo(this.SZ_PX.left - INDENT_PX, this.SZ_PX.middle_y - INDENT_PX);
      ctx.lineTo(
        this.SZ_GUTTER_PX.left + INDENT_PX,
        this.SZ_PX.middle_y - INDENT_PX
      );
      ctx.closePath();
      ctx.fill();
      return;
    }

    if (summary.horizontal === PlateH.L && summary.vertical === PlateV.B) {
      ctx.beginPath();
      ctx.moveTo(
        this.SZ_GUTTER_PX.left + INDENT_PX,
        this.SZ_GUTTER_PX.bottom - INDENT_PX
      );
      ctx.lineTo(
        this.SZ_PX.middle_x - INDENT_PX,
        this.SZ_GUTTER_PX.bottom - INDENT_PX
      );
      ctx.lineTo(
        this.SZ_PX.middle_x - INDENT_PX,
        this.SZ_PX.bottom + INDENT_PX
      );
      ctx.lineTo(this.SZ_PX.left - INDENT_PX, this.SZ_PX.bottom + INDENT_PX);
      ctx.lineTo(this.SZ_PX.left - INDENT_PX, this.SZ_PX.middle_y + INDENT_PX);
      ctx.lineTo(
        this.SZ_GUTTER_PX.left + INDENT_PX,
        this.SZ_PX.middle_y + INDENT_PX
      );
      ctx.closePath();
      ctx.fill();
      return;
    }

    if (summary.horizontal === PlateH.R && summary.vertical === PlateV.T) {
      ctx.beginPath();
      ctx.moveTo(
        this.SZ_GUTTER_PX.right - INDENT_PX,
        this.SZ_GUTTER_PX.top + INDENT_PX
      );
      ctx.lineTo(
        this.SZ_PX.middle_x + INDENT_PX,
        this.SZ_GUTTER_PX.top + INDENT_PX
      );
      ctx.lineTo(this.SZ_PX.middle_x + INDENT_PX, this.SZ_PX.top - INDENT_PX);
      ctx.lineTo(this.SZ_PX.right + INDENT_PX, this.SZ_PX.top - INDENT_PX);
      ctx.lineTo(this.SZ_PX.right + INDENT_PX, this.SZ_PX.middle_y - INDENT_PX);
      ctx.lineTo(
        this.SZ_GUTTER_PX.right - INDENT_PX,
        this.SZ_PX.middle_y - INDENT_PX
      );
      ctx.closePath();
      ctx.fill();
      return;
    }

    if (summary.horizontal === PlateH.R && summary.vertical === PlateV.B) {
      ctx.beginPath();
      ctx.moveTo(
        this.SZ_GUTTER_PX.right - INDENT_PX,
        this.SZ_GUTTER_PX.bottom - INDENT_PX
      );
      ctx.lineTo(
        this.SZ_PX.middle_x + INDENT_PX,
        this.SZ_GUTTER_PX.bottom - INDENT_PX
      );
      ctx.lineTo(
        this.SZ_PX.middle_x + INDENT_PX,
        this.SZ_PX.bottom + INDENT_PX
      );
      ctx.lineTo(this.SZ_PX.right + INDENT_PX, this.SZ_PX.bottom + INDENT_PX);
      ctx.lineTo(this.SZ_PX.right + INDENT_PX, this.SZ_PX.middle_y + INDENT_PX);
      ctx.lineTo(
        this.SZ_GUTTER_PX.right - INDENT_PX,
        this.SZ_PX.middle_y + INDENT_PX
      );
      ctx.closePath();
      ctx.fill();
      return;
    }
  }

  private fillStrikeZoneGrid(
    ctx: CanvasRenderingContext2D,
    summary: IPlateSummary,
    color = '#FF0000'
  ) {
    const width = this.strikeZoneGridWidth('px');
    const height = this.strikeZoneGridHeight('px');

    ctx.fillStyle = color;

    const pos_x = (() => {
      switch (summary.horizontal) {
        case PlateH.L: {
          return this.SZ_PX.left;
        }
        case PlateH.C: {
          return this.SZ_PX.left + width;
        }
        case PlateH.R:
        default: {
          return this.SZ_PX.left + width * 2;
        }
      }
    })();

    const pos_y = (() => {
      switch (summary.vertical) {
        case PlateV.T: {
          return this.SZ_PX.top;
        }
        case PlateV.M: {
          return this.SZ_PX.top + height;
        }
        case PlateV.B:
        default: {
          return this.SZ_PX.top + height * 2;
        }
      }
    })();

    /** small px diffs make the box lie inside the lines */
    ctx.fillRect(
      pos_x + INDENT_PX,
      pos_y + INDENT_PX,
      width - 2 * INDENT_PX,
      height - 2 * INDENT_PX
    );
  }

  /** deprecated */
  getSliderBackground = (loc: IPlateLoc): string => {
    const summary = PlateHelper.getSummary({
      hitterPresent: false,
      strikeZone: this.PLATE_CONFIG.strikeZone,
      height_ft: loc.plate_z,
      side_ft: loc.plate_x,
    });

    return `url(/img/strike-zone/${
      summary.grid.includes('?') ? 'empty' : summary.grid
    }.png)`;
  };
}
