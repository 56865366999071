import { DownloadIcon } from '@radix-ui/react-icons';
import { Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTable } from 'components/common/table';
import { ACTIONS_KEY } from 'enums/tables';
import { t } from 'i18next';
import { IDisplayCol } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineProcess } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachineLogFile } from 'lib_ts/interfaces/i-machine-log-file';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';
import { MainService } from 'services/main.service';

const RECORDS_PER_REQUEST = Object.values(MachineProcess).length;

interface IProps {
  machineID: string;
}

interface IState {
  filterDate: string[];
  filterProcess: MachineProcess[];

  noMore: boolean;
  records: IMachineLogFile[];

  options: {
    date: string[];
    process: MachineProcess[];
  };
}

export class ArchivedLogsTab extends React.Component<IProps, IState> {
  private loading = false;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Process',
      key: 'process',
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      formatFn: (m: IMachineLogFile) => {
        return (
          <DialogButton
            icon={<DownloadIcon />}
            label="common.download"
            color={RADIX.COLOR.INFO}
            onClick={() => this.download(m.path)}
          />
        );
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      noMore: false,
      filterDate: [],
      filterProcess: [],
      records: [],
      options: {
        date: [],
        process: [],
      },
    };

    this.download = this.download.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount(): void {
    this.loadData(false);
  }

  private async download(path: string) {
    const result = await MainService.getInstance().signUrls(
      [path],
      'getObject'
    );

    if (!result || !result[path]) {
      NotifyHelper.error({
        message_md: t('common.request-failed-msg'),
      });
      return;
    }

    window.open(result[path].url);
  }

  private loadData(notify: boolean) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    AdminMachinesService.getInstance()
      .getLatestMachineLogFiles({
        machineID: this.props.machineID,
        skip: this.state.records.length,
        limit: RECORDS_PER_REQUEST,
      })
      .then((results) => {
        const next = [...this.state.records, ...results];

        this.setState(
          {
            noMore: results.length < RECORDS_PER_REQUEST,
            records: next,
            options: {
              date: ArrayHelper.unique(next.map((m) => m.date)).sort(),
              process: ArrayHelper.unique(next.map((m) => m.process)).sort(),
            },
          },
          () => {
            if (notify) {
              const anyFilters =
                this.state.filterProcess.length > 0 ||
                this.state.filterDate.length > 0;
              const single = results.length === 1;

              NotifyHelper.success({
                message_md: anyFilters
                  ? `Fetched ${results.length} more ${
                      single ? 'log' : 'logs'
                    }, showing filtered results only.`
                  : `Fetched ${results.length} more ${
                      single ? 'log' : 'logs'
                    }, showing all results.`,
              });
            }
          }
        );
      })
      .finally(() => (this.loading = false));
  }

  render() {
    const filteredData = this.state.records
      .filter(
        (m) =>
          this.state.filterDate.length === 0 ||
          this.state.filterDate.includes(m.date)
      )
      .filter(
        (m) =>
          this.state.filterProcess.length === 0 ||
          this.state.filterProcess.includes(m.process)
      );

    return (
      <ErrorBoundary componentName="MachineArchivedLogsTab">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <CommonFormGrid columns={2}>
            <CommonSearchInput
              id="archived-logs-processes"
              label="Processes"
              options={this.state.options.process.map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterProcess}
              onChange={(v) => {
                this.setState({ filterProcess: v as MachineProcess[] });
              }}
              multiple
            />
            <CommonSearchInput
              id="archived-logs-date"
              label="common.created"
              options={this.state.options.date.map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterDate}
              onChange={(v) => {
                this.setState({ filterDate: v });
              }}
              multiple
            />
          </CommonFormGrid>

          <CommonTable
            id="MachineLogFiles"
            displayColumns={this.BASE_COLUMNS}
            displayData={filteredData}
            suspendKeyListener
          />

          {!this.state.noMore && (
            <DialogButton
              icon={<DownloadIcon />}
              label="Load More"
              className="btn-block"
              onClick={() => this.loadData(true)}
            />
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
