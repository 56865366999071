import { Box, Flex, Separator } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormButton } from 'components/common/form/button';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { getETAForShots } from 'components/machine/dialogs/training/controls';
import { RecentMetrics } from 'components/sections/machine-calibration/recent-metrics';
import { ICookiesContext } from 'contexts/cookies.context';
import {
  IMachineCalibrationContext,
  MODEL_BUILDER_SHOTS_OPTIONS,
  REF_LIST_SHOTS_OPTIONS,
  canCollectData,
} from 'contexts/machine-calibration.context';
import { IMachineContext } from 'contexts/machine.context';
import { isBefore, parseISO } from 'date-fns';
import { CookieKey } from 'enums/cookies.enums';
import { CalibrationStep } from 'enums/machine.enums';
import { t } from 'i18next';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'Setup';

interface IProps {
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  calibrationCx: IMachineCalibrationContext;
}

interface IState {}

export class Setup extends React.Component<IProps, IState> {
  private init = false;
  private shotsInput?: CommonSelectInput;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.startCollectData = this.startCollectData.bind(this);
  }

  componentDidMount(): void {
    if (!this.init) {
      this.init = true;

      if (this.props.calibrationCx.lists.length === 1) {
        const firstList = this.props.calibrationCx.lists[0];

        if (firstList._id !== this.props.cookiesCx.machineCalibration.list_id) {
          this.props.cookiesCx.setCookie(CookieKey.machineCalibration, {
            list_id: firstList._id,
            shots: firstList.default_calibration_shot_count,
          });
        }
      }
    }
  }

  private startCollectData(resetStart: boolean) {
    if (!this.props.machineCx.checkActive()) {
      return;
    }

    if (!this.props.calibrationCx.activeList) {
      NotifyHelper.warning({
        message_md: 'Please select a list and try again.',
      });
      return;
    }

    const existingStartDate = this.props.cookiesCx.machineCalibration.start_date
      ? parseISO(this.props.cookiesCx.machineCalibration.start_date)
      : new Date();

    // now if resetting, otherwise it's based on when the machine was last changed, otherwise it's the existing value
    const minStartDate = resetStart
      ? new Date()
      : this.props.machineCx.machine.last_hardware_changed
      ? parseISO(this.props.machineCx.machine.last_hardware_changed)
      : existingStartDate;

    const nextStartDate = isBefore(existingStartDate, minStartDate)
      ? minStartDate
      : existingStartDate;

    this.props.cookiesCx.setCookie(CookieKey.machineCalibration, {
      start_date: nextStartDate.toISOString(),
      machineID: this.props.machineCx.machine.machineID,
      ball_type: this.props.machineCx.machine.ball_type,
    });

    this.props.calibrationCx.setStep(CalibrationStep.CollectData);
  }

  render() {
    const lists = this.props.calibrationCx.lists;

    const selectedList = this.props.calibrationCx.lists.find(
      (l) => l._id === this.props.cookiesCx.machineCalibration.list_id
    );

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <ReactMarkdown children={t('settings.machine-calibration-msg')} />
          </Box>

          <Separator size="4" />

          {this.props.calibrationCx.lists.length === 0 && (
            <CommonCallout text="No reference lists available at this time." />
          )}

          {this.props.calibrationCx.lists.length > 0 && lists.length === 0 && (
            <CommonCallout text="No lists were found matching your criteria. Please try again." />
          )}

          <div
            className="hidden"
            data-testid="model_builder_default"
            data-default={
              !!this.props.calibrationCx.activeList?.model_builder_default
            }
          >
            {/* for automated tests */}
          </div>

          <CommonFormGrid columns={3}>
            <Box>
              <CommonSearchInput
                id="calibration-list"
                label="common.pitch-list"
                name="pitch-list"
                values={
                  this.props.cookiesCx.machineCalibration.list_id
                    ? [this.props.cookiesCx.machineCalibration.list_id]
                    : []
                }
                options={lists.map((l) => ({
                  label: l.name,
                  value: l._id,
                }))}
                onChange={(v) => {
                  const id = v[0];

                  if (!id) {
                    NotifyHelper.warning({
                      message_md: `A pitch list is required to proceed.`,
                    });
                    return;
                  }

                  const list = this.props.calibrationCx.lists.find(
                    (l) => l._id === id
                  );

                  if (!list) {
                    NotifyHelper.warning({
                      message_md: `Pitch list \`${id}\` could not be found. Please reload the page and try again.`,
                    });
                    return;
                  }

                  this.props.cookiesCx
                    .setCookie(CookieKey.machineCalibration, {
                      list_id: list._id,
                      shots: list.default_calibration_shot_count,
                      start_date: new Date().toISOString(),
                    })
                    .then(() => {
                      // ensures the new shots value is rendered
                      this.shotsInput?.reset();
                    });
                }}
                optional
              />
            </Box>
            <Box>
              <CommonSelectInput
                id="calibration-ball"
                name="ball_type"
                label="common.ball-type"
                options={this.props.machineCx.machine.ball_type_options.map(
                  (o) => ({ label: o, value: o })
                )}
                value={this.props.machineCx.machine.ball_type}
                onChange={(v) => {
                  const bt = v as BallType;

                  if (!bt) {
                    NotifyHelper.warning({
                      message_md: 'Ball type is required.',
                    });
                    return;
                  }

                  // cookie ball type will be set at data collection start/restart

                  this.props.machineCx.update({
                    ...this.props.machineCx.machine,
                    ball_type: bt,
                  });
                }}
                optional
              />
            </Box>
          </CommonFormGrid>

          <CommonCallout text="settings.ball-type-msg" />

          <CommonFormGrid columns={3}>
            <Box flexGrow="1">
              <CommonSelectInput
                ref={(elem) => (this.shotsInput = elem as CommonSelectInput)}
                id="calibration-shots"
                name="shots"
                label="Shots per Pitch"
                disabled={
                  selectedList?.default_calibration_shot_count !== undefined
                }
                options={
                  selectedList?.default_calibration_shot_count !== undefined
                    ? // ensure that the other options that users cannot select from here get rendered
                      REF_LIST_SHOTS_OPTIONS
                    : // default behaviour with limited options
                      MODEL_BUILDER_SHOTS_OPTIONS
                }
                value={this.props.cookiesCx.machineCalibration.shots?.toString()}
                hint_md={
                  this.props.calibrationCx.loading
                    ? 'Loading...'
                    : this.props.cookiesCx.machineCalibration.shots !==
                      undefined
                    ? `Estimated ~${getETAForShots(
                        this.props.cookiesCx.machineCalibration.shots *
                          this.props.calibrationCx.pitches.length,
                        'min'
                      )} min to complete`
                    : undefined
                }
                onOptionalNumericChange={(v) => {
                  if (v === undefined) {
                    NotifyHelper.warning({
                      message_md: 'Shots per pitch is required.',
                    });
                    return;
                  }

                  if (v <= 0) {
                    NotifyHelper.warning({
                      message_md: 'Shots per pitch must be positive.',
                    });
                    return;
                  }

                  this.props.cookiesCx.setCookie(CookieKey.machineCalibration, {
                    shots: v,
                    start_date: new Date().toISOString(),
                  });
                }}
                optional
                skipSort
              />
            </Box>
            <Box flexGrow="1">
              <CommonFormButton
                id="calibration-start-over"
                label="Start Over"
                className="btn-block"
                title="Click to reset the data collected and start over."
                disabled={
                  !canCollectData(
                    this.props.cookiesCx.machineCalibration,
                    this.props.calibrationCx.pitches.length
                  )
                }
                onClick={() => this.startCollectData(true)}
              />
            </Box>
            <Box>
              <CommonFormButton
                id="calibration-next"
                label="Next Step"
                inputColor={RADIX.COLOR.SUCCESS}
                className="btn-block"
                disabled={
                  !canCollectData(
                    this.props.cookiesCx.machineCalibration,
                    this.props.calibrationCx.pitches.length
                  )
                }
                onClick={() => this.startCollectData(false)}
              />
            </Box>
          </CommonFormGrid>

          <Separator size="4" />

          <Box>
            <RecentMetrics
              machineCx={this.props.machineCx}
              calibrationCx={this.props.calibrationCx}
            />
          </Box>
        </Flex>
      </ErrorBoundary>
    );
  }
}
